import React from 'react';
import AboListing from '../components/roasterAdmin/aboListing';

const RoasterAdminPage = () => {
  return (
    <div className="page_content">
      <AboListing/>
    </div>
  );
};

export default RoasterAdminPage;
