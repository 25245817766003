import React from "react";
import styles from "./SideDrawer.module.scss";

const SideDrawer = ({ isOpen, onClose, BodyComponent = null }) => {
  return (
    <>
      {/* Overlay */}
      <div className={`${styles.overlay} ${isOpen ? styles.showOverlay : ""}`} onClick={onClose}></div>

      {/* Side Drawer */}
      <div className={`${styles.sideDrawer} ${isOpen ? styles.open : ""}`}>
        <div className={styles.drawerContent}>
          <button className={styles.closeBtn} onClick={onClose}>
            ✕
          </button>
          {BodyComponent && BodyComponent}
        </div>
      </div>
    </>
  );
};

export default SideDrawer;
