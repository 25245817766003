export const ABO_LIST = [
    {
        "id": 1,
        "aboName": "Neeraj Chavan",
        "aboNumber": "8782727212",
        "partyID": "1234123",
        "status": "Completed",
        "startDate": "06/20/2024 9:00:00 AM EDT",
        "statusDate": "06/20/2024 9:50:00 AM EDT",
        "score": "87",
        "passed": "Yes"
    },
    {
        "id": 2,
        "aboName": "ABC",
        "aboNumber": "8782727212",
        "partyID": "1234123",
        "status": "In Progress",
        "startDate": "06/20/2024 9:00:00 AM EDT",
        "statusDate": "06/20/2024 9:50:00 AM EDT",
        "score": "",
        "passed": ""
    },
    {
        "id": 3,
        "aboName": "John Doe",
        "aboNumber": "1234567890",
        "partyID": "5432145",
        "status": "Completed",
        "startDate": "06/21/2024 10:00:00 AM EDT",
        "statusDate": "06/21/2024 10:30:00 AM EDT",
        "score": "92",
        "passed": "Yes"
    },
    {
        "id": 4,
        "aboName": "Jane Smith",
        "aboNumber": "0987654321",
        "partyID": "6789054",
        "status": "Completed",
        "startDate": "06/22/2024 11:00:00 AM EDT",
        "statusDate": "06/22/2024 11:45:00 AM EDT",
        "score": "45",
        "passed": "No"
    },
    {
        "id": 5,
        "aboName": "Michael Johnson",
        "aboNumber": "1122334455",
        "partyID": "9876543",
        "status": "In Progress",
        "startDate": "06/23/2024 1:00:00 PM EDT",
        "statusDate": "06/23/2024 1:30:00 PM EDT",
        "score": "",
        "passed": ""
    },
    {
        "id": 6,
        "aboName": "Emily Davis",
        "aboNumber": "6677889900",
        "partyID": "2345678",
        "status": "Completed",
        "startDate": "06/24/2024 3:00:00 PM EDT",
        "statusDate": "06/24/2024 3:45:00 PM EDT",
        "score": "78",
        "passed": "Yes"
    },
    {
        "id": 7,
        "aboName": "David Brown",
        "aboNumber": "4455667788",
        "partyID": "1234567",
        "status": "In Progress",
        "startDate": "06/25/2024 2:00:00 PM EDT",
        "statusDate": "06/25/2024 2:15:00 PM EDT",
        "score": "",
        "passed": ""
    },
    {
        "id": 8,
        "aboName": "Laura Wilson",
        "aboNumber": "9988776655",
        "partyID": "4567890",
        "status": "Completed",
        "startDate": "06/26/2024 4:00:00 PM EDT",
        "statusDate": "06/26/2024 4:30:00 PM EDT",
        "score": "90",
        "passed": "Yes"
    },
    {
        "id": 9,
        "aboName": "Chris Evans",
        "aboNumber": "5556667777",
        "partyID": "1112223",
        "status": "In Progress",
        "startDate": "06/27/2024 10:30:00 AM EDT",
        "statusDate": "06/27/2024 11:00:00 AM EDT",
        "score": "",
        "passed": ""
    },
    {
        "id": 10,
        "aboName": "Angela White",
        "aboNumber": "8889990000",
        "partyID": "4445556",
        "status": "Completed",
        "startDate": "06/28/2024 12:00:00 PM EDT",
        "statusDate": "06/28/2024 12:45:00 PM EDT",
        "score": "95",
        "passed": "Yes"
    },
    {
        "id": 11,
        "aboName": "Robert Green",
        "aboNumber": "3332221111",
        "partyID": "7778889",
        "status": "Completed",
        "startDate": "06/29/2024 1:30:00 PM EDT",
        "statusDate": "06/29/2024 2:15:00 PM EDT",
        "score": "40",
        "passed": "No"
    },
    {
        "id": 12,
        "aboName": "Olivia Taylor",
        "aboNumber": "1112223333",
        "partyID": "9990001",
        "status": "Completed",
        "startDate": "06/30/2024 3:00:00 PM EDT",
        "statusDate": "06/30/2024 3:50:00 PM EDT",
        "score": "88",
        "passed": "Yes"
    },
    {
        "id": 13,
        "aboName": "Liam King",
        "aboNumber": "4445556666",
        "partyID": "2223334",
        "status": "In Progress",
        "startDate": "07/01/2024 4:00:00 PM EDT",
        "statusDate": "07/01/2024 4:30:00 PM EDT",
        "score": "",
        "passed": ""
    },
];
