import { marketEnvConfig } from "../marketEnvConfig";

export class BaseService {
    static configData = null;

    // Initialize configData once and reuse it across derived classes
    static getConfigData() {
        if (!this.configData) {
            this.configData = marketEnvConfig(); // Fetch config once
        }

        return this.configData;
    }
}
