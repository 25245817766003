import { makeRequest } from "../components/utils/APIsUtils/httpsUtils";
import { BaseService } from "./BaseService";

export default class RoasterService extends BaseService {

    // Sample request for fetching learning paths. Later on we can update it to fetch ABO List.
    static async getAboList(payload) {
        const configData = this.getConfigData();
        const response = await makeRequest({
            method: 'POST',
            endpoint: `/admin/api/v1/learning-records/search`,
            body: payload
        });

        if (response != 'ERR_NETWORK' && response?.data) return response;
        else throw new Error("Failed to fetch learning path details"); // Need to add this login in makeRequest function
    }

    // Roaster Admin Request for deleting multiple learning paths from List
    static async deleteLearningRecords({ selectedAboList, selectedCourse, selectedAffCode }) {
        const response = await makeRequest({
            method: 'POST',
            endpoint: `/admin/api/v1/learning-records/bulk-delete`,
            body: {
                aboDetails: selectedAboList?.map(a => ({...a, salesPlanAff: selectedAffCode })),
                entryId: selectedCourse?.courseId,
                courseName: selectedCourse?.primaryTitle
            }
        });

        if (response != 'ERR_NETWORK' && response?.data) return response;
        else throw new Error("Failed to fetch learning path details"); 
    }
}
