import React, { useEffect, useState } from 'react';
// import styles from './DateRangePicker.module.scss';
import styles from './datePickerWithTimeSelection.module.scss';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/style.css';
import dayjs from 'dayjs';
import { setNotificationData } from '../../../redux/actions/uiActions';
import { useDispatch, useSelector } from 'react-redux';


const DatePickerWithTimeSelection = (props) => {
    const { flexProperty, marginLeft, dateType = 'Status', date, setDateHanlder, disabled = false, label = '', startCourseDate = null } = props;
    const dispatch = useDispatch();

    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const DATE_FORMAT = useSelector(state => state?.uiState?.remoteConfig?.date_format || 'MM/DD/YYYY');
    const [selectedDateString, setSelectedDateString] = useState(date ? dayjs(date?.formattedDate).format(DATE_FORMAT) : '');
    const [hour, setHour] = useState(date?.hour || '');
    const [minute, setMinute] = useState(date?.minute || '');
    const [period, setPeriod] = useState(date?.period || '');
    const [month, setMonth] = useState(new Date());
    const [isApplyEnable, setIsApplyEnable] = useState(false);
    const currentDate = new Date();
    const minDate = dayjs(startCourseDate?.formattedDate, `${DATE_FORMAT} h:mm:ss A`).toDate()
    const maxDate = currentDate;

    useEffect(() => {
        if (selectedDateString && hour && minute && period) {
            setIsApplyEnable(true)
        }
    }, [selectedDate, hour, minute])

    useEffect(() => {
        if (date?.formattedDate) {
            const parsedDate = dayjs(date.formattedDate, `${DATE_FORMAT} hh:mm:ss A`).toDate();
            console.log('parsedDate: ', parsedDate);
            setSelectedDate(parsedDate);
        }
        if (date?.hour && date?.minute && date?.period) {
            console.log('parsedDate: ', date);
            setHour(date.hour);
            setMinute(date.minute);
            setPeriod(date.period);
        }
    }, [])


    const resetValues = () => {
        if (date?.formattedDate) {
            const parsedDate = dayjs(date.formattedDate, `${DATE_FORMAT} hh:mm:ss A`).toDate();
            console.log('parsedDate: ', parsedDate);
            setSelectedDate(parsedDate);
        }
        if (date?.hour && date?.minute && date?.period) {
            setHour(date.hour);
            setMinute(date.minute);
            setPeriod(date.period);
        }
        else {
            setSelectedDate(null);
            setHour('');
            setMinute('');
            setPeriod('AM');
            setIsApplyEnable(false)
        }

    }

    const handleMonthChange = (newMonth) => {
        setMonth(newMonth);
    };

    const handleCalendarClose = () => {
        if (disabled) {
            dispatch(setNotificationData({
                show: true,
                type: 'FAILURE',
                title: 'Disabled',
                description: `Please select course status first`,
            }))

            return;
        }
        setIsCalendarOpen(!isCalendarOpen)
        resetValues();
    };

    const handleDateSelect = (date) => {
        setSelectedDateString(dayjs(date).format(DATE_FORMAT));
        setSelectedDate(date);
    };


    const handleHourChange = (e) => {
        let value = e.target.value.replace(/[^0-9]/g, ''); // Allow only digits
        if (parseInt(value) > 12) {
            value = '12'; // Limit to 12
        } else if (parseInt(value) < 1) {
            value = '01'; // Minimum value is 1
        }
        setHour(value);
    };

    const handleMinuteChange = (e) => {
        let value = e.target.value.replace(/[^0-9]/g, ''); // Allow only digits
        if (parseInt(value) > 59) {
            value = '59'; // Limit to 59
        }
        setMinute(value);
    };


    const handlePeriodChange = (e) => {
        hour.length === 1 && setHour(prev => prev.padStart(2, '0'));
        minute.length === 1 && setMinute(prev => prev.padStart(2, '0'));
        if (e.key.toLowerCase() === 'a') {
            setPeriod('AM');
        } else if (e.key.toLowerCase() === 'p') {
            setPeriod('PM');
        }
    };

    const handlePeriodScroll = (e) => {
        hour.length === 1 && setHour(prev => prev.padStart(2, '0'));
        minute.length === 1 && setMinute(prev => prev.padStart(2, '0'));
        // Handle scroll event to toggle AM/PM
        if (e.deltaY < 0) {
            // Scrolling up
            setPeriod((prevPeriod) => (prevPeriod === 'AM' ? 'PM' : 'AM'));
        } else if (e.deltaY > 0) {
            // Scrolling down
            setPeriod((prevPeriod) => (prevPeriod === 'AM' ? 'PM' : 'AM'));
        }
    };

    const handleApply = () => {
        if (dateType === 'Status') {
            const currentDate = new Date();
            const currentHour = currentDate.getHours() % 12 || 12; // Convert to 12-hour format
            const currentMinute = currentDate.getMinutes();
            const currentPeriod = currentDate.getHours() < 12 ? 'AM' : 'PM';

            const courseStartDateObj = dayjs(startCourseDate?.formattedDate, `${DATE_FORMAT} h:mm:ss A`);
            const selectedDateObj = dayjs(selectedDate);

            // Condition 1: If selectedDate is the same as courseStartDate
            if (courseStartDateObj.isSame(selectedDateObj, 'day')) {
                const courseStartHour = parseInt(startCourseDate?.hour, 10);
                const courseStartMinute = parseInt(startCourseDate?.minute, 10);
                const courseStartPeriod = startCourseDate?.period;

                if (courseStartPeriod !== period) {
                    if (courseStartPeriod === 'AM' && period === 'PM') {
                        // PM is always greater than AM, no issue
                    } else {
                        dispatch(setNotificationData({
                            show: true,
                            type: 'FAILURE',
                            title: 'Something went wrong',
                            description: `Status time must be greater than or equal to ${startCourseDate?.hour}:${startCourseDate?.minute} ${startCourseDate?.period}.`,
                        }));
                        let setDate = {
                            formattedDate: '',
                            hour: '',
                            minute: '',
                            period: ''
                        }
                        setDateHanlder(setDate)
                        return;
                    }
                } else if (
                    parseInt(hour, 10) < courseStartHour ||
                    (parseInt(hour, 10) === courseStartHour && parseInt(minute, 10) < courseStartMinute)
                ) {
                    dispatch(setNotificationData({
                        show: true,
                        type: 'FAILURE',
                        title: 'Something went wrong',
                        description: `Status time must be greater than or equal to ${startCourseDate?.hour}:${startCourseDate?.minute} ${startCourseDate?.period}.`,
                    }));
                    let setDate = {
                        formattedDate: '',
                        hour: '',
                        minute: '',
                        period: ''
                    }
                    setDateHanlder(setDate)
                    return;
                }
            }

            // Condition 2: If selectedDate is the current date
            if (dayjs(selectedDate).isSame(currentDate, 'day')) {
                // Check if selected time is greater than current time
                if (
                    period === currentPeriod &&
                    (parseInt(hour, 10) > currentHour ||
                        (parseInt(hour, 10) === currentHour && parseInt(minute, 10) > currentMinute))
                ) {
                    dispatch(setNotificationData({
                        show: true,
                        type: 'FAILURE',
                        title: 'Something went wrong',
                        description: `Status time cannot be greater than the current time (${currentHour}:${String(currentMinute).padStart(2, '0')} ${currentPeriod}).`,
                    }));
                    let setDate = {
                        formattedDate: '',
                        hour: '',
                        minute: '',
                        period: ''
                    }
                    setDateHanlder(setDate)
                    return;
                }
            }

        }
            const formattedTime = `${parseInt(hour)}:${minute.padStart(2, '0')}:00 ${period}`;
            const statusString = `${selectedDateString} ${formattedTime}`;
            let setDate = {
                formattedDate: statusString,
                hour: hour,
                minute: minute,
                period: period
            }
            setDateHanlder(setDate)
      
    };

    return (
        <div className={styles.dateRangeCalendarContainer} style={{ flex: flexProperty, marginLeft: marginLeft }}>
            {label && <label><b>{label}</b></label>}
            <div className={[styles.dateSelectorDropDownContainer, disabled ? styles.disabled : ''].join(' ')}>
                <div className={styles.selectedDate}>{date && date?.formattedDate ? date?.formattedDate : dateType + ' Date'} </div>
                <div className={styles.icon_wrapper} onClick={handleCalendarClose}>
                    <div className={isCalendarOpen ? styles.upArrow : styles.downArrow} />
                </div>
            </div>
            {isCalendarOpen && (
                <div className={styles.calendarContainer}>
                    <div className={styles.calendarHeader}>
                        <div className={styles.calendarHeaderText}>Select a {dateType} Date</div>
                        <div className={styles.closeIcon} onClick={handleCalendarClose}></div>
                    </div>
                    <div className={styles.lineDivider}></div>
                    <div className={styles.calendarDescription}>
                        Manually select the date and time the status change occurred.
                    </div>
                    <DayPicker
                        mode="single"
                        selected={selectedDate}
                        onSelect={handleDateSelect}
                        month={month}
                        onMonthChange={handleMonthChange}
                        timeZone="Asia/Calcutta"
                        captionLayout="dropdown-years"
                        classNames={{
                            root: `rdp-root ${styles.root}`,
                            dropdowns: `rdp-dropdowns ${styles.dropdowns}`,
                            months: `rdp-months ${styles.months}`,
                            button_previous: `rdp-button_previous ${styles.button_previous}`,
                            button_next: `rdp-button_next ${styles.button_next}`,
                            weekday: `rdp-weekday ${styles.weekday}`,
                        }}
                        modifiersClassNames={{
                            selected: `rdp-selected ${styles.selected}`,
                        }}
                        disabled={dateType === 'Status' ? {
                            before: minDate,
                            after: maxDate,
                        } : false}
                    />
                    <div className={styles.timeContainer}>
                        <div className={styles.timeTextContainer}>
                            <div className={styles.icon_wrapper} onClick={handleCalendarClose}>
                                <div className={styles.timeWatch} />
                            </div>
                            <div className={styles.timeText}>{dateType} Time</div>
                        </div>
                        <div className={styles.timeInputContainer}>
                            <input
                                type="text"
                                value={hour}
                                onChange={handleHourChange}
                                maxLength={2}
                                placeholder={hour ? hour : '12'}
                                className={styles.inputBoxes}
                            />
                            <span>:</span>
                            <input
                                type="text"
                                value={minute}
                                onChange={handleMinuteChange}
                                maxLength={2}
                                placeholder={minute ? minute : '00'}
                                className={styles.inputBoxes}
                            />
                            {/* <input
                                type="text"
                                value={period}
                                onKeyDown={handlePeriodChange}
                                readOnly
                                onWheel={handlePeriodScroll}
                                className={styles.inputBoxes}
                            /> */}
                            <select value={period ? period : 'AM'} onChange={(e) => setPeriod(e?.target?.value)} style={{ border: '2px solid #2C2C2C', borderRadius: '12px' }}>
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>
                        </div>
                    </div>
                    <div className={styles.filter_button_wrapper}>
                        <button
                            type="button"
                            className={[styles.btn, styles.m_lr_6].join(' ')}
                            onClick={() => {
                                handleCalendarClose()
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className={[
                                styles.btn,
                                !isApplyEnable ? styles.btn_disable :
                                    styles.dark,
                                styles.m_lr_6,
                            ].join(' ')}
                            onClick={(e) => {
                                // formatDateRange(selected);
                                handleApply()
                                setIsCalendarOpen(!isCalendarOpen);
                            }}
                        >
                            Apply
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DatePickerWithTimeSelection;
