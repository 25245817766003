import React from 'react';
import PropTypes from 'prop-types';
import styles from './badge.module.scss'; // Import the CSS file for styling

const Badge = ({ text, variant, style }) => {
    return (
        <span className={`${styles.badge} ${styles[`badge-${variant}`]}`} style={style}>
            {text}
        </span>
    );
};

Badge.propTypes = {
    text: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['success', 'warning']),
    style: PropTypes.object,
};

Badge.defaultProps = {
    variant: 'success', // Default color
    style: {},
};

export default Badge;
