import React from 'react';
import styles from './LearningRecordCard.module.scss';
import Badge from '../../badge';
import CheckBoxBlue from '../../shared/checkboxBlue';

const LearningRecordCard = ({ row = { }, selectedRows = [], extractRowKey = () => {}, onSelectionChangeCallBack = () => {}, setSelectTotalRecordsCb = () => {}, index }) => {
  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <span className={styles.title}>ARE SEE</span>
        <Badge
          text={row?.status || '---'}
          variant={row?.status === 'Completed' ? 'success' : 'warning'}
        />
      </div>
      <div className={styles.details}>
        <div className={styles.infoContainer}>
          <div className={styles.infoItem}>
            <span><b>ABO#:</b> {row?.aboNumber || '---'}</span>
          </div>
          <div className={styles.infoItem}>
            <span><b>Party ID:</b> {row?.partyId || '---'}</span>
          </div>
          <div className={styles.infoItem}>
            <span><b>Score:</b> {row?.score || '---'} </span>
          </div>
          <div className={styles.infoItem}>
            <span><b>Passed:</b> {row?.passed === true ? 'Yes' : row?.passed === false ? 'No' : '---'}</span>
          </div>
          <div className={styles.infoItem}>
            <span><b>Start:</b> {row?.startDate || '---'}</span>
          </div>
          <div className={styles.infoItem}>
            <span><b>Status:</b> {row?.statusDate || '---'}</span>
          </div>
        </div>
        <div className={styles.checkboxContainer}>
          <CheckBoxBlue
            isChecked={selectedRows.find((selectedRow) => extractRowKey(row) === extractRowKey(selectedRow))}
            handleCheckClick={(isChecked) => {
                let newSelectedRows;
                if (!isChecked) {
                    newSelectedRows = [...selectedRows].filter((selectedRow) => extractRowKey(row) !== extractRowKey(selectedRow));
                } else {
                    newSelectedRows = [...selectedRows];
                    newSelectedRows.push(row);
                }
                onSelectionChangeCallBack(newSelectedRows, index);
                if (!isChecked) {
                    setSelectTotalRecordsCb(isChecked);
                }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default LearningRecordCard;
