/**
 * Represents a rich grid component that supports checkbox selection and sorting.
 *
 * @param {Column[]} columns - A list of column objects with rendering logic and styles for each column. The length determines the number of columns in the grid.
 * @typedef {Object} Column
 * @property {string} field - Field for sorting the rich grid. If a column does not have a field, it is not sortable.
 * @property {string|React.Component} header - Heading of the column. If not set, the heading will be empty.
 * @property {RenderLogic} renderLogic - Rendering logic given row data. If not set, the column will be empty.
 * @property {Object} containerStyles - Any styling of the column item's container can be passed via this prop.
 * @property {function} [cellStyleFn] - Optional function to provide custom styles for the cell based on row data.
 *
 * @param {Object[]} data - A list of objects; the structure of the objects does not matter.
 * @param {function} extractRowKey - Callback function called on each row to get a unique key.
 *
 * @param {SortProps} sortProps - Props needed for the grid to be responsive to sort actions.
 * @typedef {Object} SortProps
 * @property {string} field - Field to sort on, one of the headers.
 * @property {string} dir - Direction of sort, should be one of the values in the SORT_DIRECTIONS enum.
 * @property {function} onChange - Callback function to be called on sort change. If not passed, the grid will not respond to sort changes.
 *
 * @param {boolean} [selectable] - Determines if rows can be selected.
 * @param {Object[]} [selectedRows] - List of currently selected rows.
 * @param {function} onSelectionChangeCallBack - Callback function called when the selection changes.
 * @param {function} onHeaderSelectionChangeCallBack - Callback function called when the header checkbox changes.
 * @param {function} rowStyleFn - Optional function to provide custom styles for rows.
 * @param {function} NoRecordsFoundComponent - Component to render when no records are found. Defaults to a simple message.
 *
 * Note: The grid itself does not sort the data; it displays sorting options and helps with callbacks for changing sort order.
 */

import React, {useState} from 'react';
import {SORT_DIRECTIONS} from './constants';
import styles from './RichGrid.module.scss';
import './richgrid.scss';
import CheckBoxBlue from '../checkboxBlue';
import table_sorting_icon from '../../../styles/images/table_sorting_icon.svg';
import LoadingShimmerList from '../LoadingShimmerList';

export default function RichGrid({
  isLoading = false,
  columns = [],
  data = [],
  selectable = null,
  selectedRows = [],
  onSelectionChangeCallBack = (rows) => {},
  onHeaderSelectionChangeCallBack = (rows) => {},
  extractRowKey,
  sortProps = {
    field: null,
    dir: SORT_DIRECTIONS.NONE,
    onChange: () => {},
  },
  rowStyleFn = null,
  setSelectTotalRecordsCb = () => {},
  NoRecordsFoundComponent = () => <h5>No Records Found</h5>,
  paginationProps = {
    pageSize: 10,
    keys: [],
    currentPageNo: 1,
  },
  onClickShowNextRecordsCb = () => {},
  onClickShowPreviousRecordsCb = () => {},
}) {
  const [headerCheckbox, setHeaderCheckbox] = useState(false);
  const {pageSize, keys, currentPageNo} = paginationProps;

  const getChangedSortPropsOnClick = (changedSortField) => {
    if (sortProps.field == changedSortField) {
      if (sortProps.dir == SORT_DIRECTIONS.ASC) {
        return {field: changedSortField, dir: SORT_DIRECTIONS.DESC};
      } else {
        return {field: changedSortField, dir: SORT_DIRECTIONS.ASC};
      }
    } else {
      return {field: changedSortField, dir: SORT_DIRECTIONS.ASC};
    }
  };

  const selectableColumn = {
    field: 'checkbox',
    isCheckboxField: true,
    header: (
      <CheckBoxBlue
        isChecked={headerCheckbox || data.length === selectedRows.length}
        handleCheckClick={(isChecked) => {
          onHeaderSelectionChangeCallBack(isChecked ? data : []);
          setHeaderCheckbox(isChecked);
          if (!isChecked) {
            setSelectTotalRecordsCb(isChecked);
          }
        }}
      />
    ),
    renderLogic: (row, indx) => (
      <CheckBoxBlue
        isChecked={selectedRows.find(
          (selectedRow) => extractRowKey(row) === extractRowKey(selectedRow),
        )}
        handleCheckClick={(isChecked) => {
          let newSelectedRows;
          if (!isChecked) {
            newSelectedRows = [...selectedRows].filter(
              (selectedRow) => extractRowKey(row) !== extractRowKey(selectedRow),
            );
          } else {
            newSelectedRows = [...selectedRows];
            newSelectedRows.push(row);
          }
          onSelectionChangeCallBack(newSelectedRows, indx);
          if (!isChecked) {
            setSelectTotalRecordsCb(isChecked);
          }
        }}
      />
    ),
  };

  const gridColumns = [...columns];
  if (selectable) gridColumns.unshift(selectableColumn);

  return (
    <div className={styles.container}>
      {isLoading ? (
        <LoadingShimmerList count={10} />
      ) : (
        <>
          <div className={styles.tableContainer}>
            <table id="richGridTable" className={styles.table}>
              <thead>
                <tr key="richgrid-thead" className={styles.headerRowContainer}>
                  {gridColumns.map((column, idx) => {
                    const isSortable = !!column.field;
                    const isSorted =
                      isSortable &&
                      sortProps.field === column.field &&
                      sortProps.dir !== SORT_DIRECTIONS.NONE;

                    return (
                      <th
                        key={`column-${idx}`}
                        className={[
                          styles.commonRowContainer,
                          column?.isCheckboxField ? styles.checkboxColumnStyles : [],
                        ].join(' ')}
                      >
                        <div
                          onClick={() =>
                            column.field &&
                            sortProps.onChange(getChangedSortPropsOnClick(column.field))
                          }
                        >
                          <div
                            className={
                              isSortable
                                ? styles.headerCellContainerSortable
                                : styles.headerCellContainer
                            }
                          >
                            {column.header}
                            {isSortable && column.field !== 'checkbox' && (
                              <div className={styles.sortIcon}>
                                <img src={table_sorting_icon} alt="sort-icon" />
                                {/* Uncomment below lines if in future any requirement comes to show the up/down arrow while sorting */}
                                {/* {isSorted &&
                                                            sortProps.dir === SORT_DIRECTIONS.DESC ? <span>Up Arrow</span> : <span>Down Arrow</span>
                                                        } */}
                              </div>
                            )}
                          </div>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {data.map((row, idx) => {
                  const rowStyle = rowStyleFn ? rowStyleFn(row, idx) : [];
                  return (
                    <>
                      <tr
                        key={`table-row-${idx}`}
                        className={[styles.tableBodyRowContainer, rowStyle].join(' ')}
                      >
                        {gridColumns.map((column, idx) => (
                          <td
                            key={`table-data-${idx}`}
                            className={[
                              styles.commonRowContainer,
                              column.cellStyleFn ? column.cellStyleFn(row) : [],
                            ].join(' ')}
                          >
                            {column.renderLogic(row, idx)}
                          </td>
                        ))}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>

          {console.log('keys ', {keys, currentPageNo})}

          {/* Table Pagination */}
          <div className={styles.paginationContainer}>
            {data?.length > 0 && (
              <button
                className={styles.paginationNextBtn}
                onClick={onClickShowPreviousRecordsCb}
                disabled={currentPageNo === 1}
              >
                Show Previous {pageSize} Records
              </button>
            )}

            {data?.length > 0 && (
              <p className={styles.paginatedRecordsTxt}>
                Showing records {currentPageNo === 1 ? 1 : currentPageNo + pageSize - 1} to{' '}
                {currentPageNo === 1 ? pageSize : currentPageNo * pageSize}
              </p>
            )}

            {data?.length > 0 && (
              <button
                className={styles.paginationNextBtn}
                onClick={onClickShowNextRecordsCb}
                disabled={!keys[currentPageNo]?.exclusiveStartKey}
              >
                Show Next {pageSize} Records
              </button>
            )}
          </div>

          {/* No Records Found Component */}
          {data.length == 0 ? NoRecordsFoundComponent() : null}
        </>
      )}
    </div>
  );
}
