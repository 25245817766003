import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  deleteLearningRecords,
  fetchAboList,
  setAboListPaginationProps,
  setAboListResetPaginationProps,
  setAboListSortProps,
  setCourseStatusDropdown,
  setEditFormCourseScore,
  setEditFormStartDate,
  setEditFormStatusDate,
  setSelectedAboList,
  setSelectedAffiliateCode,
} from '../../../redux/actions/roaster/roasterListingActions';
import styles from './AboListing.module.scss';
import Header from '../../shared/header';
import {useHistory} from 'react-router-dom/cjs/react-router-dom';
import RichGrid from '../../shared/richgrid';
import {ABO_LIST} from './data';
import Badge from '../../badge';
import RoasterNewComponent from '../../shared/RoasterNewComponent';
import DeleteConfirmationModal from '../deleteRoasterConfirmation';
import ModalWrapper from '../../shared/Modal/ModalWrapper/ModalWrapper';
import Background from '../../shared/Modal/Background/Background';
import RoasterFilterModal, {DropDown} from '../../shared/RoasterFilterModal';
import FilterComponent from '../../shared/filter';
import {Hint} from 'react-autocomplete-hint';
import DateRangerPicker from '../../shared/dateRangerPicker';
import Notification from '../../shared/notification';
import CheckBoxBlue from '../../shared/checkboxBlue';
import LearningRecordCard from '../learningRecordCard';
import AdditionIcon from '../../../styles/images/addition_icon_blue.svg';
import { setNotificationData } from '../../../redux/actions/uiActions';
import { convertFullDateToUTC, formatDateInUserTimezone } from '../../../utils/date';

const _initialFilterData = {
  filtersList: [
    {
      filterType: 'RADIO',
      filterTypeData: {
        sectionTitle: 'Filter',
        sectionData: [
          {
            index: 0,
            name: 'All',
            id: 1,
            checked: true,
            filterParameter: '',
            filterParameterType: 'default',
            typeOfFilter: 'RADIO',
          },
          {
            index: 1,
            name: 'In Progress',
            id: 2,
            checked: false,
            filterParameter: 'In-Progress',
            filterParameterType: 'ASC',
            typeOfFilter: 'RADIO',
          },
          {
            index: 2,
            name: 'Completed',
            id: 3,
            checked: false,
            filterParameter: 'Completed',
            filterParameterType: 'DESC',
            typeOfFilter: 'RADIO',
          },
        ],
      },
    },
  ],
};

const ActionButtonIcon = (props) => {
  const { flexProperty = 0, marginLeft = 0, actionBtnType = '', handleOpenModal = () => { } } = props;
  const backGroundUrl = {
    flexProperty: flexProperty,
    backgroundImage: `url(${actionBtnType})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: '18px',
    height: '18px',
  };
  return (
    <>
      <div className={styles.actionBtnContainer} onClick={handleOpenModal}>
        <div className={styles.actionBtn} style={backGroundUrl}></div>
      </div>
    </>
  );
};

const AboListing = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const router = useHistory();
  const selectedCourse = useSelector((state) => state?.courseManagement?.selectedCourse);
  const affCodeList = useSelector((state) => state?.roasterListing?.affiliateCodeList);

  const [search, setSearch] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [predictedSearch, setPredictedSearch] = useState([]);
  const [onSearchFocus, setonSearchFocus] = useState(false);
  const [filterData, setFilterData] = useState(_initialFilterData);
  const [selectedFilter, setSelectedFilter] = useState({
    index: 0,
    name: 'All',
    id: 1,
    checked: true,
    filterParameter: '',
    filterParameterType: 'default',
    typeOfFilter: 'RADIO',
  });

  const currentDate = new Date();
  const from = new Date();
  from.setDate(currentDate.getDate() - 2); // Set 'from' to two days ago
  const to = new Date(); // 'to' is the current date

  const [selectedFilterDate, setSelectedFilterDate] = useState({
    from: from,
    to,
  });

  const { notificationData } = useSelector(state => state?.uiState);
  const DATE_FORMAT = useSelector(state => state?.uiState?.remoteConfig?.date_format || 'MM/DD/YYYY');

  const {
    aboListLoader,
    aboList,
    selectedAboList,
    aboListSortProps,
    aboListPaginationProps,
    selectedAffCode,
  } = useSelector((state) => state?.roasterListing);

  const [addNewRoaster, setAddNewRoaster] = useState(false);
  const [input, setInput] = useState('');
  const [isDeleteRecord, setDeleteRecord] = useState(false);

  const handleCloseAddRecordModal = (callApi) => {
    if(callApi){
      dispatch(fetchAboList({ payload: getFetchAboListPayload(), paginationKeys: aboListPaginationProps?.keys }));
    }
    setAddNewRoaster(false);
  };

  const handleOpenAddRecordModal = () => {
    setAddNewRoaster(true);
  };

  const handleOpenDeleteRecordModal = () => {
    if(selectedAboList?.length){
      setDeleteRecord(true);
      return
    }
    dispatch(setNotificationData({
      show: true,
      type: 'FAILURE',
      title: 'Disabled',
      description: 'Please select at least one checkbox from the table',
    }));
  };

  const getFetchAboListPayload = () => {
    const { exclusiveStartKey, createdAt } = aboListPaginationProps?.keys[aboListPaginationProps?.currentPageNo - 1] || {};

    const payload = {
      courseId: selectedCourse?.courseId,
      pageSize: aboListPaginationProps?.pageSize,
      salesPlanAff: selectedAffCode?.salesPlanAffCode,
      aboNumber: search,
      startDate: selectedFilterDate?.from || undefined,
      endDate:  selectedFilterDate?.to || undefined,
      status: selectedFilter?.filterParameter,
      exclusiveStartKey: exclusiveStartKey || '',
      createdAt: createdAt || ''
    };

    return payload;
  };

  /**
   * Reset the values of the filters
   */

  useEffect(()=>{
    if(selectedAboList?.length === 0){
      dispatch(setEditFormCourseScore({value: null}))
      dispatch(setCourseStatusDropdown({
        value: {id: '', name: ''},
        Placeholder: 'Status',
        options: [
          {id: 'Completed', name: 'Completed'},
          {id: 'In-Progress', name: 'In Progress'},
        ],
        isDisabled: true,
        disabledReason: "Please select at least one checkbox from the table"
      })); 
      dispatch( setEditFormStartDate({
        formattedDate: '',
        hour: '',
        minute: '',
        period: '',
        isDisabled: true,
        disabledReason: "Please select course status first"
      }))
      dispatch(setEditFormStatusDate( {
        formattedDate: '',
        hour: '',
        minute: '',
        period: '',
        isDisabled: true,
        disabledReason: "Please select course status first"
      }))
    }
  },[selectedAboList])

  useEffect(()=>{
    if(!aboList || aboList?.length===0){
      dispatch(setSelectedAboList([]))
    }
  },[aboList])

  useEffect(() => {
    dispatch(setAboListResetPaginationProps());
    
    window.addEventListener('beforeunload', () => {
      dispatch(setAboListResetPaginationProps());
    });

    return () => {
      window.removeEventListener('beforeunload', () => {
        dispatch(setAboListResetPaginationProps());
      });
    };
  }, []);

  useEffect(() => {
    dispatch(fetchAboList({ payload: getFetchAboListPayload(), paginationKeys: aboListPaginationProps?.keys }));
  }, [selectedAffCode, selectedFilter, aboListPaginationProps?.currentPageNo]);

  const ABO_LISTING_COLUMNS = [
    {
      field: 'name',
      header: <label style={{margin: '0px'}}>ABO Name</label>,
      renderLogic: (row, idx) => <span>{row?.name || '---'}</span>,
    },
    {
      field: 'aboNumber',
      header: <label style={{margin: '0px'}}>ABO Number</label>,
      renderLogic: (row, idx) => <span>{row?.aboNumber || '---'}</span>,
    },
    {
      field: 'partyId',
      header: <label style={{margin: '0px'}}>Party ID</label>,
      renderLogic: (row, idx) => <span>{row?.partyId || '---'}</span>,
    },
    {
      field: 'status',
      header: <label style={{margin: '0px'}}>Status</label>,
      renderLogic: (row, idx) => (
        <Badge
          text={row?.status || '---'}
          variant={row?.status === 'Completed' ? 'success' : 'warning'}
        />
      ),
    },
    {
      field: 'startDate',
      header: <label style={{margin: '0px'}}>Start Date</label>,
      renderLogic: (row, idx) => {
        const { formattedDate, timezoneAbbreviation } = formatDateInUserTimezone(row?.startDate, DATE_FORMAT);

        return <span>{row?.startDate ? `${formattedDate} ${timezoneAbbreviation}` : '---'}</span>
      }
    },
    {
      field: 'statusDate',
      header: <label style={{margin: '0px'}}>Status Date</label>,
      renderLogic: (row, idx) => {
        const { formattedDate, timezoneAbbreviation } = formatDateInUserTimezone(row?.statusDate, DATE_FORMAT);

        return <span>{row?.statusDate ? `${formattedDate} ${timezoneAbbreviation}` : '---'}</span>
      },
    },
    {
      header: <label style={{margin: '0px'}}>Score</label>,
      renderLogic: (row, idx) => <span>{row?.score || '---'}</span>,
    },
    {
      header: <label style={{margin: '0px'}}>Passed</label>,
      renderLogic: (row, idx) => (
        <span>{row?.passed === true ? 'Yes' : row?.passed === false ? 'No' : '---'}</span>
      ),
    },
  ];

  const NoRecordsFoundComponent = () => (
    <div className="text-left">
      <p className="m-0">No Learning Records found.</p>
      <p className="m-0">
        Try adjusting the dates, verifying your search terms, or confirming you have selected the
        correct course.
      </p>
    </div>
  );

  const handleDeleteInput = (props) => {
    const {value, setInput} = props;
    setInput(value);
  };

  const handleDeleteRecord = () => {
    dispatch(deleteLearningRecords({ selectedAboList, selectedCourse, selectedAffCode }, getFetchAboListPayload()));
    setDeleteRecord(false);
    dispatch(setSelectedAboList([]));
  };

  const handleSearchInput = (event) => {
    setSearch(event?.target?.value);
    setPredictedSearch([]);
  };

  const handleAddNewRoaster=()=>{
     setAddNewRoaster(true)
  }

  const SearchBar = (
    <div className={[styles.search_main_wrapper, search.length > 0 ? styles.active : ''].join(' ')}>
      <div className={[styles.search_wrapper, onSearchFocus ? styles.outline : ''].join(' ')}>
        <Hint options={predictedSearch} allowTabFill={true}>
          <input
            type="text"
            placeholder="ABO Number"
            value={search}
            onChange={(e) => handleSearchInput(e)}
            onKeyPress={(e) => handleSearchInput(e)}
            className={styles.search_input}
            // onKeyDown={(e) => {
            //   if (e.keyCode === 13 && search?.length < 3 && search?.length > 0) {
            //     setNotificationData({
            //       show: false,
            //       type: 'WARNING',
            //       title: 'Insufficient Search Input',
            //       description: 'To search for any Course, enter 3 or more characters.',
            //     });
            //   }
            // }}
            onFocus={() => {
              setonSearchFocus(true);
            }}
            onBlur={() => {
              search?.length == 0 && setonSearchFocus(false);
            }}
          />
        </Hint>
        {/* {search?.length === 0 && !onSearchFocus && ( */}
        <div
          className={styles.icon_wrapper}
          onClick={() => dispatch(fetchAboList({ payload: getFetchAboListPayload(), paginationKeys: aboListPaginationProps?.keys }))}
        >
          <div className={styles.search} />
        </div>
        {/* )} */}
        {/* {(search?.length > 0 || onSearchFocus) && (
            <div
              className={styles.icon_wrapper}
              onClick={() => {
                setSearch('');
                setonSearchFocus(false);
              }}
            >
              <div className={styles.cross_icon} />
            </div>
          )} */}
      </div>
    </div>
  );

  const sortAboListRecords = () => {
    if (!aboList || !aboList.length || !aboListSortProps) return [];

    const {field, dir} = aboListSortProps;

    // Sorting function to handle different data types
    const compare = (a, b) => {
      let valueA = a[field];
      let valueB = b[field];

      // Convert dates to Date objects for proper comparison
      if (field === 'startDate' || field === 'statusDate') {
        valueA = new Date(valueA); // Convert to Date object
        valueB = new Date(valueB); // Convert to Date object
      }

      // Handle number comparisons (for numeric fields like score)
      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return valueA - valueB;
      }

      // Handle string comparisons (for fields like name, ABO number)
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueA.localeCompare(valueB);
      }

      // Default case if the field is of unexpected type
      return 0;
    };

    // Sort the list based on the field and direction
    const sortedList = [...aboList].sort((a, b) => {
      const result = compare(a, b);
      return dir === 1 ? result : -result; // Ascending or Descending based on dir
    });

    // Return the sorted list to be used
    return sortedList;
  };

  const sortedAboList = sortAboListRecords();

  return (
    <>
      <div className={styles.aboListing_wrapper}>
        <Header
          headerName={`ABO Roster - ${selectedCourse?.primaryTitle}`}
          btnName1="Back to Course Management"
          isBtn1={true}
          handleButton={() => router.push('/Coursesmanagement')}
        />

        <main className={[styles.main_content_wrapper, styles.inner_wrapper].join(' ')}>
          <div className={styles.top_area_wrapper}>
            <div className={styles.sort_by_wrapper}>
              {/* <div className={styles.txt}>Filter Table</div> */}
              <div className={[styles.icon_wrapper].join(' ')}>
                <div className={styles.filter} onClick={() => setShowFilters(!showFilters)} />
                {showFilters && (
                  <FilterComponent
                    isPills={true}
                    filterData={filterData}
                    col6={true}
                    handleFilterApply={(e) => {
                      setSelectedFilter(e[0]);
                    }}
                    handleFilterCancle={() => setShowFilters(false)}
                  />
                )}
              </div>
            </div>

            <DropDown
              list={affCodeList}
              dropdownStyles={{padding: '5px 15px', margin: 'auto', height: 'auto'}}
              onChange={(value) => dispatch(setSelectedAffiliateCode(value))}
              selectedItem={selectedAffCode}
            />

            {SearchBar}

            <DateRangerPicker 
              onDateRangeChange={(range) => setSelectedFilterDate(range)} 
              onClickApplyCb={() => {
                dispatch(setAboListResetPaginationProps());
                dispatch(fetchAboList({ payload: getFetchAboListPayload(), paginationKeys: aboListPaginationProps?.keys }))
              }} 
            />
            <div className={styles.add_addition_icons}>
              <ActionButtonIcon
                actionBtnType={AdditionIcon}
                marginLeft={8}
                handleOpenModal={handleAddNewRoaster}
              />
            </div>
          </div>

          <RoasterFilterModal
            handleOpenAddRecordModal={handleOpenAddRecordModal}
            handleOpenDeleteRecordModal={handleOpenDeleteRecordModal}
            getFetchAboListPayload={getFetchAboListPayload}
            // Using redux states for below code
            // startDate={startDate}
            // setStartDate={setStartDate}
            // statusDate={statusDate}
            // setStatusDate={setStatusDate}
          />

          <div className={styles.learningRecordCardContainer}>
            {sortedAboList?.length > 0 ? sortedAboList?.map((row, index) => (
              <LearningRecordCard key={row?.rowId} row={row} 
              index={index} extractRowKey={(row) => row.rowId} selectedRows={selectedAboList} onSelectionChangeCallBack={(selectedData) => dispatch(setSelectedAboList(selectedData))}
              onHeaderSelectionChangeCallBack={(selectedData) =>
                dispatch(setSelectedAboList(selectedData))
              } />
            )) : <NoRecordsFoundComponent/>}
          </div>

          <div className={styles.richGridContainer}>
            <RichGrid
              isLoading={aboListLoader}
              data={sortedAboList}
              columns={ABO_LISTING_COLUMNS}
              selectable={true}
              extractRowKey={(row) => row.rowId}
              onSelectionChangeCallBack={(selectedData) => dispatch(setSelectedAboList(selectedData))}
              onHeaderSelectionChangeCallBack={(selectedData) =>
                dispatch(setSelectedAboList(selectedData))
              }
              selectedRows={selectedAboList}
              NoRecordsFoundComponent={NoRecordsFoundComponent}
              sortProps={{
                ...aboListSortProps,
                onChange: (updatedSortProps) => dispatch(setAboListSortProps(updatedSortProps)),
              }}
              paginationProps={aboListPaginationProps}
              onClickShowNextRecordsCb={() => {
                dispatch(setAboListPaginationProps({ currentPageNo: aboListPaginationProps?.currentPageNo + 1 }));
              }}
              onClickShowPreviousRecordsCb={() => {
                dispatch(setAboListPaginationProps({ currentPageNo: aboListPaginationProps?.currentPageNo - 1 }));
              }}
            />
          </div>

        </main>

        {addNewRoaster && (
          <RoasterNewComponent
            handleCloseAddRecordModal={handleCloseAddRecordModal}
            affCodeList={affCodeList}
            selectedCourse={selectedCourse}
          />
        )}

        {/* Roaster Delete Modal */}
        <DeleteConfirmationModal
          isDeleteRecord={isDeleteRecord}
          handleCloseDeleteModal={() => setDeleteRecord(false)}
          handleDeleteInput={(value) =>
            handleDeleteInput({
              value,
              setInput,
            })
          }
          handleDeleteImage={(value) =>
            handleDeleteInput({
              value,
              setInput,
            })
          }
          input={input}
          handleDeleteRecord={() => handleDeleteRecord()}
          selectedAboList={selectedAboList}
          selectedCourse={selectedCourse?.primaryTitle}
          //  notificationData={notificationData}
          //  ImageDetail={ImageDetail}
          ModalWrapper={ModalWrapper}
          Background={Background}
          //  Notification={Notification}
        />
      </div>

      {notificationData?.show && <Notification />}
    </>
  );
};

export default AboListing;
