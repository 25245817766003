import React, {useEffect, useState, useRef} from 'react';
import {Hint} from 'react-autocomplete-hint';
import styles from './Content.module.scss';
import Header from '../shared/header';
import Pills from '../shared/pills';
import ReplaceImageModal from '../contentManagement/ReplaceImageModal';
import CroppingModal from '../contentManagement/CroppingModal';
import FilterComponent from '../shared/filter';
import {connect, useDispatch} from 'react-redux';
import {findIndex, find} from 'lodash';
import {useHistory} from 'react-router-dom';
import Notification from '../shared/notification';
import {
  setNotificationData,
  setDashboardNotificationCountApi,
  setNotificationListApi,
  setSelectedNavigationOption,
  setIsUserLoggedIn,
  setCourseMode,
  setLearningPath,
} from '../../redux/actions/uiActions';
import {
  setImageListAll,
  setButton_169_status,
  setButton_11_status,
  setReplacedImage,
  setCroppedImage_16_9,
  setCroppedImage_1_1,
  setImageData,
  setMainImageBackup,
  setDeletePreviousImage,
  setCroppedImageData11,
  setCroppedImageData169,
  setCroppedImageData,
  setSelectedImage_1_1_Backup,
  setSelectedImage_16_9_Backup,
} from '../../redux/actions/imageCrop/imageCropAction';
import {setNewThumbnailId, setBlockNavigation, setNewCourseWizardState} from '../../redux/actions/newCourseActions';
import EmptyScreenComponent from '../shared/emptyScreen';
import {setErrorStateTemplate} from '../../redux/actions/uiActions';
import {makeRequest} from '../utils/APIsUtils/httpsUtils';
import {
  setFilter,
  setCroppedImage,
  fetchListOnSearch,
  setCategory,
  searchAndSort,
  setImageDetailsData,
  getUpdatedImageDetails,
  setDataUpdatedNotification,
  setTempPredition,
  updateImageName,
  getCalculatedWidth,
  setTags,
} from '../utils/ContentManagementUtils/ContentManagementMainUtils';
import Background from '../shared/Modal/Background/Background';
import ModalWrapper from '../shared/Modal/ModalWrapper/ModalWrapper';
import Strings from '../utils/Localization/Strings';
import DrawerFailScreen from '../shared/drawerFailScreen';
import DrawerImageNameInput from './DrawerImageNameInput';
import SearchCrossIcon from './SearchCrossIcon';
import ToggleSwitchHandle from './ToggleSwitchHandle';
import CourseLearningPathWizardHandle from './CourseLearningPathWizardHandle';
import CourseStatusComponent from './CourseStatusComponent';
import HandleAddTagsComponent from './HandleAddTagsComponent';
import ImageCardView from './ImageCardView';
import ImageListView from './ImageListView';
import NoInstanceMessage from './NoInstanceMessage';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import DeleteImageModal from './DeleteImageModal';
import ImageReplaceHandle from './ImageReplaceHandle';
import {
  dummyFunction,
  drawerOverlayCalculatedHeight,
  mobDrawerToOrignalState,
  drawerCalculatedHeight,
  pullbox,
} from '../utils/commonUtil';
import {
  setSuggestion,
  deleteImageMessage,
  searchValidation,
  handleImageName,
  getUsedFileName,
  handleDescriptionChange,
  keywordInputHandle,
  closeSortAndFilter,
  handleDescription,
  handleCloseImageLib,
  drawerOutsideClick,
  radioButtonFilterData,
  handleToggleSwitch,
  closeImageLib,
  setSuggestionData,
  getMainImage,
  get_16_9_image,
  setImageDrawer,
  searchEndpoint,
  handleFilterApply,
  fetchImageList,
  fetchImageDetails,
  fetchKeywords,
  mobileDrawerOutsideClick,
  handleDeleteImageAsset,
  handleOnClickReplaceImageOnAllInstances,
  closeSortAndFilterFlyout,
  handleImageNameChange,
  handleSearchInput,
  handleSelectedKeyword,
  cropButton,
  handleDate,
  handleDeletePill,
  handleKeywordInput,
  updateWindowDimensions,
  handleDeleteRecord,
  handleDeleteModal,
  handleCloseDeleteModal,
  handleImageClick,
  handleAddTags,
  handleFilters,
  handleAddNewImage,
  closeDrawerModal,
  handleReplaceAllInstance,
  closeReplaceImageModal,
  handleDeleteImage,
  handleDeleteInput,
  courseWizardCheck,
  learningPathWizardCheck,
  searchLengthCheck,
  drawerOpenCheck,
} from '../utils/ContentManagementUtils/ContentManagementLanding';
import LoadingShimmerList from '../shared/LoadingShimmerList';
import LoadingShimmerCustom from '../shared/LoadingShimmerCustom';
import { setNewLPWizardState } from '../../redux/actions/learningPathActions';

const cropImageCheck = (
  isDrawer=false,
  mainImg,
  newCourseWizardState,
  learningPathMode,
  newLPWizardState,
) => {
  
  return (
    isDrawer || mainImg
    /**
     * commenting this because newCourseWizardState?.courseWizardOpen and 
     * newLPWizardState.WizardOpen will be empty if its the default contentManagement Page
     * which will return false and negation will return true and the drawer wont open in that case.
     * 
     * Check should only be on if the isDrawer props has been passed via ImageHandleClick 
     *  --> If yes the drawer should open
     *  --> If nothing is passed the default value is false
     */
    // && !(newCourseWizardState?.courseWizardOpen || newLPWizardState.WizardOpen)
  );
};

const ContentManagementComponent = (props) => {
  const router = useHistory();
  const {query = {}} = router.location;
  const {imgId = false} = query;

  const {
    editImageExtends,
    seteditImageExtends,
    croppedImg,
    setCroppedImg,
    // mainImg,
    // setMainImg,
    croppedImg1,
    setCroppedImg1,
    croppedImageData,
    ratio,
    setMainImageBackup,
    setImageListAll,
    mainImageBackup,
    replacedImage,
    notificationData,
    setNotificationData,
    setButton_169_status,
    setButton_11_status,
    setCroppedImage_16_9,
    setCroppedImage_1_1,
    setImageData,
    showImageLibrary,
    setCloseImageLib,
    newImageID,
    newCourseWizardState,
    courseMode,
    setNewThumbnailId,
    setDashboardNotificationCountApi,
    setNotificationListApi,
    currentUserId,
    setSelectedNavigationOption,
    setIsUserLoggedIn,
    setDeletePreviousImage,
    setBlockNavigation,
    setCroppedImageData11,
    setCroppedImageData169,
    setCroppedImageData,
    setErrorStateTemplate,
    setCourseMode,
    learningPathMode,
    newCourseData,
    newLPData,
    newLPWizardState,
    setSelectedImage_1_1_Backup,
    setSelectedImage_16_9_Backup,
  } = props;

  const dispatch = useDispatch();
  const [ImageList, setImageList] = useState([]);
  const [ImageDetail, setImageDetail] = useState([]);
  //const [searchImageList, setSearchImageList] = useState([]);
  const [keywordSuggestion, setKeywordSuggestion] = useState([]);
  const [keywordPillsData, setKeywordPillsData] = useState([]);
  const [KeywordSuggestionCopy, setKeywordSuggestionCopy] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState({title: ''});
  const [isKeywordDropdown, setKeywordDropdown] = useState(false);
  const [isCardView, setIsCardView] = useState(true);
  const [isListView, setIsListView] = useState(false);
  const [isDrawer, setDrawer] = useState(false);
  const [search, setSearch] = useState('');
  const [predictedSearch, setPredictedSearch] = useState([]);
  const [imageId, setImageId] = useState(0);
  const [courseIds, setCourseIds] = useState([]);
  const [courseDetails, setCourseDetails] = useState([]);
  const [input, setInput] = useState('');
  const [imageinput, setImageInput] = useState('');
  const [descriptionInput, setDescriptionInput] = useState('');
  const [isDeleteRecord, setDeleteRecord] = useState(false);
  const [width, setWidth] = useState(0);
  //const [height, setHeight] = useState(0);
  //const [isDelete, setDelete] = useState(true);
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [cropDialogue, setCropDialogue] = useState(false);
  const [isReplaceAllInstance, setReplaceAllInstance] = useState(false);
  const [isfileError, setFileError] = useState(false);
  const [usedFileNameList, setUsedFileName] = useState([]);
  const [showReplaceModal, setShowReplaceModal] = useState(false);
  const [isImageDetailDrawerUpdated, setImageDetailDrawerUpdated] = useState(false);
  //const [apiStatus, setApiStatus] = useState("");
  const [imageThumbnailButtonClick, setImageThumbnailButtonClick] = useState(false);
  const [updatedKeywordSuggestionCopy, setUpdatedKeywordSuggestionCopy] = useState([]);
  const [isSearchFilterEmptyState, setSearchFilterEmptyState] = useState(false);
  const [isDrawerAPIFailed, setDrawerAPIFailed] = useState(false);
  const [drawerIdForBrokenLink, setDrawerIdForBrokenLink] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [mainImg, setMainImg] = useState(undefined);
  // const regexName = new RegExp("^[a-zA-Z0-9?:!\"'. -]*$");
  const regexNameChinese =
    /[^\u4E00-\u9FFF\u3400-\u4DFF\uF900-\uFAFF0-9a-zA-Z.。?？:：!！\"”“'‘’ -]+/g;
  //const regexNameChinese = /[^\u4E00-\u9FFF\u3400-\u4DFF\uF900-\uFAFF-\u3002-\uFF1F-\uFF1A-\u201D0-9a-zA-Z.?:!\"' -]+/g;

  const [isServerError, setIsServerError] = useState(false);
  const [isOnline, setOnline] = useState(true);

  const [drawerHeight, setDrawerHeight] = useState('');

  const handlePopState = (event) => {
    setMainImageBackup(null);
    setNewThumbnailId(null);
    setMainImg(null);
    setBlockNavigation(false);
  };

  useEffect(() => {
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  window.addEventListener('online', () => {
    setOnline(true);
    setErrorStateTemplate({
      status: false,
      variant: 'networkError',
    });
    window.location.reload();
  });
  window.addEventListener('offline', () => {
    setOnline(false);
    setErrorStateTemplate({
      status: true,
      variant: 'networkError',
    });
  });

  const _filterData = {
    filtersList: [
      {
        filterType: 'RADIO',
        filterTypeData: {
          sectionTitle: 'Sort By',
          sectionData: [
            {
              index: 0,
              name: 'Recent Activity',
              id: 1,
              checked: true,
              filterParameter: 'name',
              filterParameterType: 'default',
              typeOfFilter: 'RADIO',
              paramForAPI: 'Recent_Activity',
            },
            {
              index: 1,
              name: 'A - Z',
              id: 2,
              checked: false,
              filterParameter: 'description',
              filterParameterType: 'ASC',
              typeOfFilter: 'RADIO',
              paramForAPI: 'A_Z',
            },
            {
              index: 2,
              name: 'Z - A',
              id: 3,
              checked: false,
              filterParameter: 'description',
              filterParameterType: 'DESC',
              typeOfFilter: 'RADIO',
              paramForAPI: 'Z_A',
            },
            {
              index: 3,
              name: 'Newest To Oldest',
              id: 4,
              checked: false,
              filterParameter: 'updatedAt',
              filterParameterType: 'TIME-ASC',
              typeOfFilter: 'RADIO',
              paramForAPI: 'Newest_To_Oldest',
            },
            {
              index: 4,
              name: 'Oldest To Newest',
              id: 5,
              checked: false,
              filterParameter: 'updatedAt',
              filterParameterType: 'TIME-DESC',
              typeOfFilter: 'RADIO',
              paramForAPI: 'Oldest_To_Newest',
            },
          ],
        },
      },
      {
        filterType: 'CHECKBOX',
        filterTypeData: {
          sectionTitle: 'Filter By',
          sectionData: [
            {
              index: 0,
              id: 0,
              label: 'In-Use',
              title: 'In-Use',
              filterParameter: 'category',
              checked: true,
              typeOfFilter: 'CHECKBOX',
            },
            {
              index: 1,
              id: 1,
              label: 'Not In-Use',
              title: 'Not In-Use',
              filterParameter: 'category',
              checked: true,
              typeOfFilter: 'CHECKBOX',
            },
          ],
        },
      },
    ],
  };

  const [filterData, setFilterData] = useState(_filterData);

  const modifyValue = (value) => value; // Search Modifier

  const sortAndFilterFlyout = useRef(null);

  useEffect(async () => {
    setIsLoading(true);
    setOnline(navigator.onLine);
    setIsUserLoggedIn(true);
    setSelectedNavigationOption('CONTENT-MANAGEMENT');

    updateWindowDimensions({setWidth});
    await fetchImageList({
      filterData,
      search,
      makeRequest,
      setSearchFilterEmptyState,
      setErrorStateTemplate,
      isOnline,
      setImageList,
      setImageListAll,
      searchAndSort,
      setFilterDropdown,
      setCategory,
    });
    window.addEventListener('resize', () => {
      updateWindowDimensions({setWidth});
    });

    document?.addEventListener('mousedown', (e) => {
      closeSortAndFilterFlyout({
        e,
        sortAndFilterFlyout,
        filterDropdown,
        setFilterDropdown,
      });
    });

    document.addEventListener(
      'mousedown',
      (event) => {
        mobileDrawerOutsideClick({
          event,
          closeDrawerModal,
          filterData,
          search,
          makeRequest,
          setSearchFilterEmptyState,
          setErrorStateTemplate,
          isOnline,
          setImageList,
          setImageListAll,
          searchAndSort,
          setFilterDropdown,
          setCategory,
          setDrawer,
          setMainImg,
          setImageId,
        });
      },
      true,
    );

    setCroppedImage(replacedImage, setImageId, setShowReplaceModal, imgId, setCropDialogue);
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    return () => {
      // Anything in here is fired on component unmount.
      window.removeEventListener('resize', () => {
        updateWindowDimensions({setWidth});
      });
      document.removeEventListener(
        'click',
        (event) => {
          mobileDrawerOutsideClick({
            event,
            closeDrawerModal,
            filterData,
            search,
            makeRequest,
            setSearchFilterEmptyState,
            setErrorStateTemplate,
            isOnline,
            setImageList,
            setImageListAll,
            searchAndSort,
            setFilterDropdown,
            setCategory,
            setDrawer,
            setMainImg,
            setImageId,
          });
        },
        true,
      );
      document.removeEventListener(
        'mousedown',
        (event) => {
          mobileDrawerOutsideClick({
            event,
            closeDrawerModal,
            filterData,
            search,
            makeRequest,
            setSearchFilterEmptyState,
            setErrorStateTemplate,
            isOnline,
            setImageList,
            setImageListAll,
            searchAndSort,
            setFilterDropdown,
            setCategory,
            setDrawer,
            setMainImg,
            setImageId,
          });
        },
        true,
      );
    };
  }, []);

  useEffect(() => {
    fetchListOnSearch({
      fetchImageList,
      search,
      filterData,
      makeRequest,
      setSearchFilterEmptyState,
      setErrorStateTemplate,
      isOnline,
      setImageList,
      setImageListAll,
      searchAndSort,
      setFilterDropdown,
      setCategory,
    });
  }, [search]);

  useEffect(() => {
    learningPathMode !== 'FROM_LEARNING_PATH' &&
      setImageDrawer({
        imageId,
        setDrawer,
        fetchKeywords,
        fetchImageDetails,
        learningPathMode,
        makeRequest,
        isImageDetailDrawerUpdated,
        setNotificationData,
        setImageDetailsData,
        imageinput,
        setImageDetailDrawerUpdated,
        setImageDetail,
        setImageInput,
        setDescriptionInput,
        setCourseIds,
        setMainImageBackup,
        setDrawerAPIFailed,
        drawerIdForBrokenLink,
        setDrawerIdForBrokenLink,
        setCourseDetails,
        setKeywordSuggestion,
        setKeywordSuggestionCopy,
      });
  }, [imageId]);

  useEffect(() => {
    // if (courseIds?.length > 0) {
    //   fetchCourseDetails();
    // }
    // if (courseIds?.length === 0) {
    //   setCourseDetails([]);
    // }
  }, [courseIds]);

  const updateImageDetails = async (payload) => {
    const _endpoint = `/admin/api/v1/images/${ImageDetail?.imageId}`;
    let dataUpdateImageDetails = await makeRequest({
      method: 'PATCH',
      endpoint: _endpoint,
      body: payload,
    });

    getUpdatedImageDetails(
      dataUpdateImageDetails,
      fetchKeywords,
      fetchImageList,
      setNotificationData,
      filterData,
      search,
      makeRequest,
      setSearchFilterEmptyState,
      setErrorStateTemplate,
      isOnline,
      setImageList,
      setImageListAll,
      searchAndSort,
      setFilterDropdown,
      setCategory,
      setKeywordSuggestion,
      setKeywordSuggestionCopy,
    );

    setDashboardNotificationCountApi(currentUserId);
    setNotificationListApi(currentUserId);
  };

  useEffect(() => {
    setDataUpdatedNotification(
      isImageDetailDrawerUpdated,
      isDrawer,
      imageinput,
      setNotificationData,
      setImageDetailDrawerUpdated,
    );
    setDrawerHeight(140);
  }, [isImageDetailDrawerUpdated, isDrawer]);

  useEffect(() => {
    if (drawerHeight >= 600) {
      closeDrawerModal({
        filterData,
        search,
        makeRequest,
        setSearchFilterEmptyState,
        setErrorStateTemplate,
        isOnline,
        setImageList,
        setImageListAll,
        searchAndSort,
        setFilterDropdown,
        setCategory,
        setDrawer,
        setMainImg,
        setImageId,
      });
    }
  }, [drawerHeight]);

  const handleSaveDeleteChanges = () => {
    //setDelete(false);
  };

  useEffect(() => {
    closeImageLib({
      newImageID,
      showImageLibrary,
      setCloseImageLib,
      setNewThumbnailId,
      courseMode,
      setCourseMode,
      learningPathMode,
    });
  }, [newImageID]);

  useEffect(() => {
    handleCloseImageLib({newCourseWizardState, showImageLibrary, setCloseImageLib});
  }, [newCourseWizardState]);

  useEffect(() => {
    courseWizardCheck({courseMode, setBlockNavigation});
  }, [courseMode]);
  useEffect(() => {
    learningPathWizardCheck({learningPathMode, setBlockNavigation});
  }, [learningPathMode]);

  useEffect(() => {
    let emptarry = [];
    ImageDetail?.tags?.map((data, index) => {
      let tempObj = {
        id: index,
        title: data,
      };
      emptarry.push(tempObj);
    });
    setKeywordPillsData(emptarry);
  }, [ImageDetail]);

  const getShimmerList = (count) => {
    let content = [];
    for (let i = 0; i < count; i++) {
      content.push(
        <div className={[styles.flex_item, ''].join(' ')}>
          <LoadingShimmerCustom className={[styles.image_wrapper].join(' ')} />{' '}
        </div>,
      );
    }
    return content;
  };
  return (
    <React.Fragment>
      <div
        className={[
          styles.content_mgt_wrapper,
          learningPathMode === 'FROM_LEARNING_PATH' || courseMode == 'FROM_COURSE_WIZARD'
            ? styles.content_mgt_wrapper_disable_side_nav
            : '',
        ].join(' ')}
      >
        {/* Header Component */}
        <Header
          headerName={Strings.IMG_LIBRARY}
          headerXSName=""
          btnName1="Cancel"
          btnName2="Save changes"
          isHeaderXS={false}
          isBtn1={false}
          isSideNavigationPresent={
            learningPathMode === 'FROM_LEARNING_PATH' || courseMode == 'FROM_COURSE_WIZARD'
              ? false
              : true
          }
          isBtn2={false}
        />
        {/* Header Component */}

        {/* Delete modal */}
        {showReplaceModal && (
          <Background>
            <ModalWrapper>
              <ReplaceImageModal
                closeReplaceImageModal={() => {
                  closeReplaceImageModal({setShowReplaceModal});
                }}
                saveDeleteChanges={handleSaveDeleteChanges}
                ImageDetail={ImageDetail}
                //setSearchImageList={(data) => setSearchImageList(data)}
                setImageList={(data) => setImageList(data)}
                setImageListAll={(data) => setImageListAll(data)}
                isImageDetailDrawerUpdated={isImageDetailDrawerUpdated}
                setImageDetailDrawerUpdated={(data) => setImageDetailDrawerUpdated(data)}
                setImageDetail={(data) => setImageDetail(data)}
                setImageInput={(data) => setImageInput(data)}
                setDescriptionInput={(data) => setDescriptionInput(data)}
                setCourseIds={(data) => setCourseIds(data)}
                imageinput={imageinput}
                handleImageClick={(data) => {
                  handleImageClick({
                    item: data,
                    setImageThumbnailButtonClick,
                    setImageId,
                    setMainImg,
                    setSelectedKeyword,
                    setFileError,
                    setKeywordDropdown,
                    setIsLoading,
                  });
                }}
                imageName={imageinput}
                imageDescription={descriptionInput}
                pillsData={ImageDetail?.tags ? ImageDetail.tags : []}
              />
            </ModalWrapper>
          </Background>
        )}
        {/* close delete */}
        {showImageLibrary && (
          <div
            className={[
              styles.audience_wrapper,
              drawerOpenCheck({isDrawer, newCourseWizardState, learningPathMode}),
              //   isDrawer && !newCourseWizardState?.courseWizardOpen ? styles.is_drawer_open : "",
              '',
            ].join(' ')}
          >
            <div className={styles.top_area_wrapper}>
              <div className={styles.sort_by_wrapper}>
                <div className={styles.txt}>Filter Table</div>
                <div className={[styles.icon_wrapper].join(' ')}>
                  <div
                    className={styles.filter}
                    onClick={() =>
                      handleFilters({
                        setFilterDropdown,
                        filterDropdown,
                      })
                    }
                    ref={sortAndFilterFlyout}
                  />
                  {filterDropdown ? (
                    <FilterComponent
                      isPills={false}
                      filterData={filterData}
                      col6={true}
                      handleFilterApply={(params) => {
                        handleFilterApply({
                          params,
                          setFilter,
                          filterData,
                          findIndex,
                          fetchImageList,
                          search,
                          makeRequest,
                          setSearchFilterEmptyState,
                          setErrorStateTemplate,
                          isOnline,
                          setImageList,
                          setImageListAll,
                          searchAndSort,
                          setFilterDropdown,
                          setCategory,
                          setIsLoading,
                        });
                      }}
                      handleFilterCancle={() => setFilterDropdown(false)}
                    />
                  ) : null}
                </div>
              </div>
              <div
                className={[
                  styles.search_main_wrapper,
                  searchLengthCheck({search}),
                  //   search.length > 0 ? styles.active : "",
                ].join(' ')}
              >
                <div
                  className={styles.search_wrapper}
                  style={{
                    width: getCalculatedWidth(isDrawer, width),
                  }}
                >
                  <Hint options={predictedSearch} allowTabFill={true}>
                    <input
                      type="text"
                      placeholder={'Search by the Image Name/ Description/ Tags'}
                      value={search}
                      onChange={(event) =>
                        handleSearchInput({
                          event,
                          modifyValue,
                          setSearch,
                          setPredictedSearch,
                          setNotificationData,
                          ImageList,
                          setTempPredition,
                        })
                      }
                      onKeyPress={(event) =>
                        handleSearchInput({
                          event,
                          modifyValue,
                          setSearch,
                          setPredictedSearch,
                          setNotificationData,
                          ImageList,
                          setTempPredition,
                        })
                      }
                      className={styles.search_input}
                    />
                  </Hint>
                  <SearchCrossIcon search={search} setSearch={setSearch} />
                </div>
              </div>

              <ToggleSwitchHandle
                newCourseWizardState={newCourseWizardState}
                isListView={isListView}
                handleToggleSwitch={(value) => {
                  handleToggleSwitch({value, setIsCardView, setIsListView});
                }}
                isCardView={isCardView}
                setIsCardView={setIsCardView}
                setIsListView={setIsListView}
                learningPathMode={learningPathMode}
              />

              <CourseLearningPathWizardHandle
                newLPData={newLPData}
                courseMode={courseMode}
                learningPathMode={learningPathMode}
                imageThumbnailButtonClick={imageThumbnailButtonClick}
                imageId={imageId}
                setNewThumbnailId={setNewThumbnailId}
                setCloseImageLib={setCloseImageLib}
                setCroppedImage_16_9={setCroppedImage_16_9}
                setCroppedImage_1_1={setCroppedImage_1_1}
                setCroppedImageData={setCroppedImageData}
                setCroppedImageData11={setCroppedImageData11}
                setCroppedImageData169={setCroppedImageData169}
                handleAddNewImage={() => {
                  dispatch(setNewLPWizardState({WizardOpen: false}));
                  dispatch(setNewCourseWizardState({courseWizardOpen: false, courseWizardStateOpen: ''}));
                  handleAddNewImage({
                    setReplacedImage,
                    setCroppedImage_16_9,
                    setCroppedImage_1_1,
                    setImageData,
                    router,
                  });
                }}
                router={router}
                Strings={Strings}
                mainImg={mainImg}
                setMainImageBackup={setMainImageBackup}
                newCourseData={newCourseData}
                setSelectedImage_1_1_Backup={setSelectedImage_1_1_Backup}
                setSelectedImage_16_9_Backup={setSelectedImage_16_9_Backup}
              />
            </div>
            <div className={styles.inner_wrapper}>
              {isCardView && !isLoading ? (
                <ImageCardView
                  isCardView={isCardView}
                  ImageList={ImageList}
                  handleImageClick={(item) => {
                    handleImageClick({
                      item: item?.item,
                      setImageThumbnailButtonClick,
                      setImageId,
                      setMainImg,
                      setSelectedKeyword,
                      mainImageBackup,
                      setFileError,
                      setKeywordDropdown,
                      setIsLoading,
                    });
                  }}
                  isSearchFilterEmptyState={isSearchFilterEmptyState}
                  imageId={imageId}
                  isDrawer={isDrawer}
                  learningPathMode={learningPathMode}
                  EmptyScreenComponent={EmptyScreenComponent}
                  setImageThumbnailButtonClick={setImageThumbnailButtonClick}
                  setImageId={setImageId}
                  setMainImg={setMainImg}
                  setSelectedKeyword={setSelectedKeyword}
                  setMainImageBackup={setMainImageBackup}
                  setFileError={setFileError}
                  setKeywordDropdown={setKeywordDropdown}
                />
              ) : isCardView && isLoading ? (
                <div className={styles.content_wrapper}>
                  <div className={[styles.image_container, ''].join(' ')}>{getShimmerList(40)}</div>
                </div>
              ) : null}
              {/* Card View Ended */}

              {/* List View Started */}
              {isListView || width < 767 ? (
                <ImageListView
                  isListView={isListView}
                  ImageList={ImageList}
                  imageId={imageId}
                  handleImageClick={(item) => {
                    handleImageClick({
                      item: item?.item,
                      setImageThumbnailButtonClick,
                      setImageId,
                      setMainImg,
                      setSelectedKeyword,
                      setFileError,
                      setKeywordDropdown,
                      setIsLoading,
                    });
                  }}
                  handleDate={(date) => {
                    handleDate(date);
                  }}
                  isSearchFilterEmptyState={isSearchFilterEmptyState}
                  EmptyScreenComponent={EmptyScreenComponent}
                  isDrawer={isDrawer}
                  learningPathMode={learningPathMode}
                  setImageThumbnailButtonClick={setImageThumbnailButtonClick}
                  setImageId={setImageId}
                  setMainImg={setMainImg}
                  setSelectedKeyword={setSelectedKeyword}
                  setMainImageBackup={setMainImageBackup}
                  setFileError={setFileError}
                  setKeywordDropdown={setKeywordDropdown}
                  isLoading={isLoading}
                />
              ) : null}

              {/* List View Ended */}
              {/* Image Details Drawer Start*/}
              {cropImageCheck(
                isDrawer,
                mainImg,
                newCourseWizardState,
                learningPathMode,
                newLPWizardState,
              ) ? (
                <div className={[styles.img_drawer_container, ''].join(' ')}>
                  <div
                    className={styles.custom_overlay}
                    style={drawerOverlayCalculatedHeight({drawerHeight: drawerHeight})}
                  ></div>
                  <div
                    className={styles.img_drawer_wrapper}
                    style={drawerCalculatedHeight({drawerHeight: drawerHeight})}
                  >
                    {!isDrawerAPIFailed ? (
                      <>
                        {cropDialogue ? (
                          <CroppingModal
                            imageId={imageId}
                            setCropDialogue={setCropDialogue}
                            editImageExtends={editImageExtends}
                            seteditImageExtends={seteditImageExtends}
                            croppedImg={croppedImg}
                            setCroppedImg={setCroppedImg}
                            mainImg={getMainImage({imgId, croppedImageData, mainImg})}
                            setMainImg={setMainImg}
                            croppedImg1={croppedImg1}
                            setCroppedImg1={setCroppedImg1}
                            imageinput={imageinput}
                            descriptionInput={descriptionInput}
                            closeDeleteModal={() => {
                              handleCloseDeleteModal({
                                setDeleteRecord,
                              });
                            }}
                            saveDeleteChanges={handleSaveDeleteChanges}
                            ImageDetail={ImageDetail}
                            fetchImageList={() =>
                              fetchImageList({
                                filterData,
                                search,
                                makeRequest,
                                setSearchFilterEmptyState,
                                setErrorStateTemplate,
                                isOnline,
                                setImageList,
                                setImageListAll,
                                searchAndSort,
                                setFilterDropdown,
                                setCategory,
                              })
                            }
                            selectedImageItem={ImageDetail}
                            fetchImageDetails={() =>
                              fetchImageDetails({
                                imageId,
                                makeRequest,
                                isImageDetailDrawerUpdated,
                                setNotificationData,
                                setImageDetailsData,
                                imageinput,
                                setImageDetailDrawerUpdated,
                                setImageDetail,
                                setImageInput,
                                setDescriptionInput,
                                setCourseIds,
                                setMainImageBackup,
                                setDrawerAPIFailed,
                                drawerIdForBrokenLink,
                                setDrawerIdForBrokenLink,
                                setCourseDetails,
                              })
                            }
                            handleImageClick={(selectedImageItem) => {
                              handleImageClick({
                                item: selectedImageItem,
                                setImageThumbnailButtonClick,
                                setImageId,
                                setMainImg,
                                setSelectedKeyword,
                                setFileError,
                                setKeywordDropdown,
                                setIsLoading,
                              });
                            }}
                            makeRequest={makeRequest}
                            isImageDetailDrawerUpdated={isImageDetailDrawerUpdated}
                            setNotificationData={setNotificationData}
                            setImageDetailsData={setImageDetailsData}
                            setImageDetailDrawerUpdated={setImageDetailDrawerUpdated}
                            setImageDetail={setImageDetail}
                            setImageInput={setImageInput}
                            setDescriptionInput={setDescriptionInput}
                            setCourseIds={setCourseIds}
                            setMainImageBackup={setMainImageBackup}
                            setDrawerAPIFailed={setDrawerAPIFailed}
                            drawerIdForBrokenLink={drawerIdForBrokenLink}
                            setDrawerIdForBrokenLink={setDrawerIdForBrokenLink}
                            setCourseDetails={setCourseDetails}
                          />
                        ) : null}
                        <div
                          className={styles.mob_bar}
                          draggable
                          onTouchMove={(e) => {
                            pullbox({e: e, setDrawerHeight: setDrawerHeight});
                          }}
                          onTouchEnd={() => {
                            mobDrawerToOrignalState({setDrawerHeight: setDrawerHeight});
                          }}
                        >
                          <div className={styles.line_bar}></div>
                        </div>
                        <div className={styles.middle_container}>
                          <div className={styles.header}>
                            <div className={styles.title}>Image Details</div>
                            <div
                              className={styles.close_wrapper}
                              onClick={() =>
                                closeDrawerModal({
                                  filterData,
                                  search,
                                  makeRequest,
                                  setSearchFilterEmptyState,
                                  setErrorStateTemplate,
                                  isOnline,
                                  setImageList,
                                  setImageListAll,
                                  searchAndSort,
                                  setFilterDropdown,
                                  setCategory,
                                  setDrawer,
                                  setMainImg,
                                  setImageId,
                                })
                              }
                            >
                              <div className={styles.close_icon} />
                            </div>
                          </div>
                          <div className={styles.img_wrapper}>
                            <img
                              // src={ImageDetail?.bannerImage ? ImageDetail.bannerImage : ""}
                              src={get_16_9_image({
                                imgId,
                                croppedImageData,
                                mainImageBackup,
                                ratio,
                              })}
                              alt="16:9 selected"
                              className={styles.selected_img}
                            />
                            <div
                              className={styles.crop_btn_wrapper}
                              onClick={() =>
                                cropButton({
                                  setButton_169_status,
                                  setButton_11_status,
                                  setCroppedImage_16_9,
                                  setCroppedImage_1_1,
                                  setCropDialogue,
                                })
                              }
                            >
                              <div className={styles.crop_btn}></div>
                            </div>
                          </div>
                          <DrawerImageNameInput
                            handleImageNameChange={(value) => {
                              handleImageNameChange({
                                value,
                                ImageList,
                                ImageDetail,
                                setFileError,
                                setImageInput,
                                updateImageName,
                                setUsedFileName,
                              });
                            }}
                            regexNameChinese={regexNameChinese}
                            handleImageName={(value) => {
                              handleImageName({
                                value,
                                ImageDetail,
                                usedFileNameList,
                                setImageDetailDrawerUpdated,
                                updateImageDetails,
                              });
                            }}
                            imageinput={imageinput}
                            isfileError={isfileError}
                            ImageDetail={ImageDetail}
                            usedFileNameList={usedFileNameList}
                            setImageDetailDrawerUpdated={setImageDetailDrawerUpdated}
                            updateImageDetails={updateImageDetails}
                          />

                          <div className={[styles.info_container, ' '].join(' ')}>
                            <div className={styles.label_wrapper}>
                              <div className={styles.label_text}>Description</div>
                              <div className={styles.tag_text}>Max 500 characters</div>
                            </div>
                            <div className={styles.form_field}>
                              <div className={styles.input_wrapper}>
                                <textarea
                                  type={'text'}
                                  placeholder={'Enter image description'}
                                  className={styles.textarea}
                                  onChange={(e) =>
                                    handleDescriptionChange({
                                      value: e.target.value,
                                      setDescriptionInput,
                                    })
                                  }
                                  onBlur={(e) =>
                                    handleDescription({
                                      value: e.target.value,
                                      descriptionInput,
                                      ImageDetail,
                                      setImageDetailDrawerUpdated,
                                      updateImageDetails,
                                    })
                                  }
                                  value={descriptionInput}
                                />
                              </div>
                            </div>
                          </div>
                          <div className={styles.upload_details_wrapper}>
                            <div className={styles.upload_info}>
                              <div className={styles.title}>Upload Date</div>
                              <div className={styles.info_text}>
                                {handleDate({date: ImageDetail?.uploadDate})}
                              </div>
                            </div>
                            <div className={styles.upload_info}>
                              <div className={styles.title}>Uploaded By</div>
                              <div className={styles.info_text}>{ImageDetail?.uploadedBy}</div>
                            </div>
                          </div>

                          <HandleAddTagsComponent
                            handleKeywordInput={(value) => {
                              handleKeywordInput({
                                value,
                                setSelectedKeyword,
                                setUpdatedKeywordSuggestionCopy,
                                keywordSuggestion,
                                setKeywordDropdown,
                                setKeywordSuggestion,
                                KeywordSuggestionCopy,
                              });
                            }}
                            selectedKeyword={selectedKeyword}
                            isKeywordDropdown={isKeywordDropdown}
                            updatedKeywordSuggestionCopy={updatedKeywordSuggestionCopy}
                            handleSelectedKeyword={(item) => {
                              handleSelectedKeyword({
                                item,
                                setSelectedKeyword,
                                setKeywordDropdown,
                              });
                            }}
                            handleAddTags={() => {
                              handleAddTags({
                                setKeywordDropdown,
                                ImageDetail,
                                setTags,
                                updatedKeywordSuggestionCopy,
                                selectedKeyword,
                                setImageDetailDrawerUpdated,
                                updateImageDetails,
                                setImageDetail,
                                setSelectedKeyword,
                              });
                            }}
                            keywordPillsData={keywordPillsData}
                            handleDeletePill={(item) => {
                              handleDeletePill({
                                item,
                                keywordPillsData,
                                setImageDetail,
                                updateImageDetails,
                                setImageDetailDrawerUpdated,
                                setKeywordPillsData,
                              });
                            }}
                            ImageDetail={ImageDetail}
                            Pills={Pills}
                          />

                          <ImageReplaceHandle
                            handleOnClickReplaceImageOnAllInstances={() => {
                              handleOnClickReplaceImageOnAllInstances({
                                setImageData,
                                setReplacedImage,
                                setCroppedImage_16_9,
                                setCroppedImage_1_1,
                                setShowReplaceModal,
                                setDeletePreviousImage,
                              });
                            }}
                            handleReplaceAllInstance={() => {
                              handleReplaceAllInstance({
                                setReplaceAllInstance,
                                isReplaceAllInstance,
                              });
                            }}
                            courseDetails={courseDetails}
                            isReplaceAllInstance={isReplaceAllInstance}
                            CourseStatusComponent={CourseStatusComponent}
                          />

                          {/* below component should be replaced above component conditionally when there is no instances  */}
                          <NoInstanceMessage courseDetails={courseDetails} />

                          <DeleteImageModal
                            handleDeleteModal={() => {
                              handleDeleteModal({
                                isDeleteRecord,
                                setDeleteRecord,
                              });
                            }}
                            courseDetails={courseDetails}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className={styles.mob_bar}
                          draggable
                          onTouchMove={(e) => {
                            pullbox({e: e, setDrawerHeight: setDrawerHeight});
                          }}
                          onTouchEnd={() => {
                            mobDrawerToOrignalState({setDrawerHeight: setDrawerHeight});
                          }}
                        >
                          <div className={styles.line_bar}></div>
                        </div>
                        <div className={styles.middle_container}>
                          <div className={styles.header}>
                            <div className={styles.title}>Image Details</div>
                            <div
                              className={styles.close_wrapper}
                              onClick={() =>
                                closeDrawerModal({
                                  filterData,
                                  search,
                                  makeRequest,
                                  setSearchFilterEmptyState,
                                  setErrorStateTemplate,
                                  isOnline,
                                  setImageList,
                                  setImageListAll,
                                  searchAndSort,
                                  setFilterDropdown,
                                  setCategory,
                                  setDrawer,
                                  setMainImg,
                                  setImageId,
                                })
                              }
                            >
                              <div className={styles.close_icon} />
                            </div>
                          </div>
                          <DrawerFailScreen
                            drawerId={drawerIdForBrokenLink}
                            drawerTryAgainCall={(item) => {
                              fetchImageDetails({
                                imageId: item,
                                makeRequest,
                                isImageDetailDrawerUpdated,
                                setNotificationData,
                                setImageDetailsData,
                                imageinput,
                                setImageDetailDrawerUpdated,
                                setImageDetail,
                                setImageInput,
                                setDescriptionInput,
                                setCourseIds,
                                setMainImageBackup,
                                setDrawerAPIFailed,
                                drawerIdForBrokenLink,
                                setDrawerIdForBrokenLink,
                                setCourseDetails,
                              });
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ) : null}
              {/* Image Details Drawer End*/}

              {/* Delete Modal */}

              <DeleteConfirmationModal
                isDeleteRecord={isDeleteRecord}
                handleCloseDeleteModal={() => {
                  handleCloseDeleteModal({
                    setDeleteRecord,
                  });
                }}
                imageinput={imageinput}
                handleDeleteInput={(value) => {
                  handleDeleteInput({
                    value,
                    setInput,
                  });
                }}
                handleDeleteImage={(value) => {
                  handleDeleteImage({
                    value,
                    setInput,
                  });
                }}
                input={input}
                handleDeleteRecord={(imageId) => {
                  handleDeleteRecord({
                    imageId,
                    makeRequest,
                    setNotificationData,
                    setDrawer,
                    setImageId,
                    setMainImg,
                    imageinput,
                    filterData,
                    search,
                    setSearchFilterEmptyState,
                    setErrorStateTemplate,
                    isOnline,
                    setImageList,
                    setImageListAll,
                    searchAndSort,
                    setFilterDropdown,
                    setCategory,
                    setDashboardNotificationCountApi,
                    setNotificationListApi,
                    currentUserId,
                    setDeleteRecord,
                    setInput,
                  });
                }}
                notificationData={notificationData}
                ImageDetail={ImageDetail}
                ModalWrapper={ModalWrapper}
                Background={Background}
                Notification={Notification}
              />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

ContentManagementComponent.propTypes = {};

const mapStateToProps = (state) => ({
  croppedImageData: state,
  ratio: state && state.croppedImage && state.croppedImage.ratio,
  croppedImage_1_1: state && state.croppedImage && state.croppedImage.croppedImage_1_1,
  croppedImage_16_9: state && state.croppedImage && state.croppedImage.croppedImage_16_9,
  mainImageBackup: state && state.croppedImage && state.croppedImage.mainImageBackup,
  replacedImage: state && state.croppedImage && state.croppedImage.replacedImage,
  notificationData: state && state.uiState && state.uiState.notificationData,
  newImageID: state && state.croppedImage && state.croppedImage.newImageID,
  newCourseWizardState: state && state.courseState && state.courseState.newCourseWizardState,
  newCourseData:
    state &&
    state.courseState &&
    state.courseState.newCourseData &&
    state.courseState.newCourseData.data,
  // image_list_all: state && state.croppedImage && state.croppedImage.image_list_all,
  courseMode: state && state.uiState && state.uiState.courseMode,
  learningPathMode: state && state.uiState && state.uiState.learningPathMode,
  currentUserId: state && state.uiState && state.uiState.currentUserId,
  newLPData: state && state.LPState && state.LPState.newLPData,
  newLPWizardState: state && state.LPState && state.LPState.newLPWizardState,
});

const mapDispatchToProps = (dispatch) => ({
  setMainImageBackup: (options) => dispatch(setMainImageBackup(options)),
  setNotificationData: (options) => dispatch(setNotificationData(options)),
  setImageListAll: (options) => dispatch(setImageListAll(options)),
  setButton_169_status: (options) => dispatch(setButton_169_status(options)),
  setButton_11_status: (options) => dispatch(setButton_11_status(options)),
  setReplacedImage: (options) => dispatch(setReplacedImage(options)),
  setCroppedImage_16_9: (options) => dispatch(setCroppedImage_16_9(options)),
  setCroppedImage_1_1: (options) => dispatch(setCroppedImage_1_1(options)),
  setImageData: (options) => dispatch(setImageData(options)),
  setNewThumbnailId: (options) => dispatch(setNewThumbnailId(options)),
  setDashboardNotificationCountApi: (options) =>
    dispatch(setDashboardNotificationCountApi(options)),
  setNotificationListApi: (options) => dispatch(setNotificationListApi(options)),
  // setCropDialogue: options => dispatch(setCropDialogue(options)),
  setSelectedNavigationOption: (options) => dispatch(setSelectedNavigationOption(options)),
  setIsUserLoggedIn: (options) => dispatch(setIsUserLoggedIn(options)),
  setDeletePreviousImage: (options) => dispatch(setDeletePreviousImage(options)),
  setCroppedImageData11: (options) => dispatch(setCroppedImageData11(options)),
  setCroppedImageData169: (options) => dispatch(setCroppedImageData169(options)),
  setCroppedImageData: (options) => dispatch(setCroppedImageData(options)),
  setBlockNavigation: (options) => dispatch(setBlockNavigation(options)),
  setErrorStateTemplate: (options) => dispatch(setErrorStateTemplate(options)),
  setCourseMode: (options) => dispatch(setCourseMode(options)),
  setLearningPath: (options) => dispatch(setLearningPath(options)),
  setSelectedImage_16_9_Backup: (options) => dispatch(setSelectedImage_16_9_Backup(options)),
  setSelectedImage_1_1_Backup: (options) => dispatch(setSelectedImage_1_1_Backup(options)),
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(ContentManagementComponent));
