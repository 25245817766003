import { Placeholder } from 'semantic-ui-react';
import {SORT_DIRECTIONS} from '../../../components/shared/richgrid/constants';
import {
  SET_ABO_LIST,
  SET_ABO_LIST_LOADER,
  SET_ABO_LIST_SORT_PROPS,
  SET_ABO_SEARCH_TERM,
  SET_AFFILIATE_CODE,
  SET_COURSE_STATUS_DROPDOWN,
  SET_SELECTED_ABO_LIST,
  SET_SELECTED_AFF_CODE,
  SET_ABO_LIST_PAGINATION_PROPS,
  SET_EDIT_FORM_COURSE_START_DATE,
  SET_EDIT_FORM_COURSE_STATUS_DATE,
  SET_EDIT_FORM_COURSE_PASSED_STATUS,
  SET_EDIT_FORM_COURSE_SCORE,
  SET_ABO_LIST_RESET_PAGINATION_PROPS
} from '../../actions/roaster/roasterListingActions';

const initialPaginationProps = {
  pageSize: 10,
  currentPageNo: 1,
  keys: [
    {
      exclusiveStartKey: null,
      createdAt: null
    },
  ]
};

const initialState = {
  affiliateCodeList: [],
  selectedAffCode: {},
  selectedCourseStatus: {},
  aboSearchTerm: '',
  searchDateRange: '',

  // -- Edit Record Fields Start -- //
  courseStatusDropdown: {
    value: {id: '', name: ''},
    Placeholder: 'Status',
    options: [
      {id: 'Completed', name: 'Completed'},
      {id: 'In-Progress', name: 'In Progress'},
    ],
    isDisabled: true,
    disabledReason: "Please select at least one checkbox from the table"
  },
  coursePassedDropdown: {
    value: {id: '', name: ''},
    placeholder: 'Passed',
    options: [
      {id: 1, name: 'Yes'},
      {id: 2, name: 'No'},
    ],
    isDisabled: true,
    disabledReason: "Please select course status first"
  },
  courseStartDate: {
    formattedDate: '',
    hour: '',
    minute: '',
    period: '',
    isDisabled: true,
    disabledReason: "Please select course status first"
  },
  courseStatusDate: {
    formattedDate: '',
    hour: '',
    minute: '',
    period: '',
    isDisabled: true,
    disabledReason: "Please select course status first"
  },
  courseScore: {
    value: '',
    isDisabled: true,
    disabledReason: "Please select course status first"
  },
  // -- Edit Record Fields End -- //

  coursePassingStatus: {
    value: '',
    options: [
      {label: 'Yes', value: 'yes'},
      {label: 'No', value: 'no'},
    ],
    isDisabled: true,
  },
  aboList: [],
  aboListLoader: false,
  selectedAboList: [],
  aboListSortProps: {
    field: null,
    dir: SORT_DIRECTIONS.NONE,
  },
  aboListPaginationProps: initialPaginationProps
};

export const roasterListingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ABO_SEARCH_TERM:
      return {
        ...state,
        aboSearchTerm: action?.payload,
      };
    case SET_COURSE_STATUS_DROPDOWN:
      return {
        ...state,
        courseStatusDropdown: {...state.courseStatusDropdown, ...action?.payload},
        coursePassedDropdown: {...state.coursePassedDropdown, isDisabled: !action?.payload?.value?.id ? true : false }
      };
    case SET_AFFILIATE_CODE:
      return {
        ...state,
        affiliateCodeList: action?.payload,
      };
    case SET_SELECTED_AFF_CODE:
      return {
        ...state,
        selectedAffCode: {...action?.payload},
      };
    case SET_ABO_LIST_LOADER:
      return {
        ...state,
        aboListLoader: action?.payload,
      };
    case SET_ABO_LIST:
      return {
        ...state,
        aboList: action?.payload,
      };
    case SET_SELECTED_ABO_LIST:
      return {
        ...state,
        selectedAboList: action?.payload,
        courseStatusDropdown: { ...state.courseStatusDropdown, isDisabled: action?.payload?.length > 0 ? false : true }
      };
    case SET_ABO_LIST_SORT_PROPS:
      return {
        ...state,
        aboListSortProps: {...state.aboListSortProps, ...action?.payload},
      };
    case SET_ABO_LIST_PAGINATION_PROPS:
      return {
        ...state,
        aboListPaginationProps: {...state.aboListPaginationProps, ...action?.payload},
      };
      case SET_ABO_LIST_RESET_PAGINATION_PROPS:
        return {
          ...state,
          aboListPaginationProps: {...initialPaginationProps},
        };
      
    case SET_EDIT_FORM_COURSE_START_DATE: 
      return {
        ...state,
        courseStartDate: { ...state.courseStartDate, ...action?.payload }
      };
    case SET_EDIT_FORM_COURSE_STATUS_DATE: 
      return {
        ...state,
        courseStatusDate: { ...state.courseStatusDate, ...action?.payload }
      };
    case SET_EDIT_FORM_COURSE_PASSED_STATUS:
        return {
          ...state,
          coursePassedDropdown: {...state.coursePassedDropdown, ...action?.payload},
        };
    case SET_EDIT_FORM_COURSE_SCORE:
        return {
          ...state,
          courseScore: {...state.courseScore, ...action?.payload},
        };
        
    default:
      return state;
  }
};
