import React, {useEffect, useRef, useState} from 'react';
import styles from './DropDown.module.scss';

const DropDown = (props) => {
  const {list = [], selectedItem = '', setCountrySelected = () => {}, isRoaster = false, isDisabled=false,onChange =()=>{}, defaultDisabledValue = {name:''}} = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const dropdownRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(()=>{
    setSelectedValue(defaultDisabledValue?.name?.length > 0 ? defaultDisabledValue : list?.length > 0 ? list[0] : {name:'---'})
  },[list])

  const handleToggleDD = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleOptionClick = (value) => {
    setSelectedValue(value);
    setIsOpen(false);
    setCountrySelected(value);
    onChange(value)
  };

  return (
    <div
      className={
        [
          styles.drop_down,
          isRoaster ? '' : styles.drop_down_margin_left,
          isDisabled ? styles.isDisabled : ''
        ].join(' ')
      }
      ref={dropdownRef}
    >
      <div
        className={
          [
            styles.selected_arrow_item,
            isRoaster ? '' : styles.selected_arrow_item_with_min_width,
             isDisabled ? styles.isDisabled : ''
          ].join(' ')
        }
        onClick={handleToggleDD}
      >
        <div className={styles.selected_item}>{selectedValue?.name}</div>
        <div className={[styles.arrow_icon,isDisabled ? styles.isDisabled : ''].join(' ')}></div>
      </div>
      {isOpen && (
        <div
          className={isRoaster ? styles.list : [styles.list, styles.list_with_min_width].join(' ')}
        >
          {list.length > 0 &&
            list.map((element, index) => (
              <div
                key={index}
                className={
                  selectedValue.name === element.name ? styles.list_item_selected : styles.list_item
                }
                onClick={() => handleOptionClick(element)}
              >
                {element.name}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default DropDown;
