import React, {useCallback, useEffect, useState} from 'react';
import styles from './RoasterNewComponent.module.scss';
import DropDown from '../dropDown';
import _debounce from 'lodash/debounce';
import { makePostRequest_V2, makeRequest } from '../../utils/APIsUtils/httpsUtils';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { setSelectedAffiliateCode } from '../../../redux/actions/roaster/roasterListingActions';
import {setNotificationData} from '../../../redux/actions/uiActions'; 
import { useDispatch, useSelector } from 'react-redux';
import { convertFullDateToUTC, convertToUTC, formatDateInUserTimezone } from '../../../utils/date';

const RoasterNewComponent = (props) => {
  /**
   * state variables
   */
  const {handleCloseAddRecordModal,affCodeList,selectedCourse} = props;
  const [enteredAboNumber,setEnteredAboNumber] = useState('');
  const [isABOValid,setIsABOValid] = useState(false);
  const [isScoreValid,setIsScoreValid] = useState(true);
  const [partyIdDisabled,setPartyIdDisabled] = useState(true);
  const [partyIdList,setPartyIdList]= useState([]);
  const selectedAffCode = useSelector((state) => state?.roasterListing?.selectedAffCode);
  const courseStatusDropdown = useSelector((state) => state?.roasterListing?.courseStatusDropdown?.options);
  const passedDropDown = useSelector((state) => state?.roasterListing?.coursePassedDropdown?.options);
  const [selectedPartyId,setSelectedPartyId] = useState({}); 
  const [selectedStatus,setSelectedStatus]  = useState({});
  const [selectedPassed,setSelectedPassed] = useState({});
  const [isAddRecordEnabled,setIsAddRecordEnabled] = useState(false);
  const [enteredScore,setEnteredScore] = useState('');
  const [isABOUserSearchLoading,setIsABOUserSearchLoading] = useState(true)
  // const selectedStatus = useSelector((state) => state?.roasterListing?.selectedStatus);  if required we can add it in redux
  const DATE_FORMAT = useSelector(state => state?.uiState?.remoteConfig?.date_format || 'MM/DD/YYYY');
  
  const { register, watch, formState: { errors },setError,
  clearErrors, } = useForm({
    mode: 'onChange', // Enable validation on change
  });
  const dispatch = useDispatch();
  const startDate = watch('startDate');
  const startTime = watch('startTime');
  const statusDate = watch('statusDate');
  const statusTime = watch('statusTime');

  /**
   * sideEffect Hooks
   */
  useEffect(() => {
    if (
      !selectedAffCode?.salesPlanAffCode ||
       !enteredAboNumber || //commenting as not a mandatory field
      !isABOValid ||
      !selectedPartyId.id ||
      !selectedStatus.id ||
      // !selectedPassed.id || //commenting as not a mandatory field
      !isScoreValid ||
      (!startDate ?? false) || (errors.startDate ?? false) ||
      (!startTime ?? false) || (errors.startTime ?? false) ||
      (!statusDate ?? false)|| (errors.statusDate ?? false) ||
      (!statusTime ?? false) || (errors.statusTime ?? false)
    ) {
      setIsAddRecordEnabled(false);
    }
    else setIsAddRecordEnabled(true);
  }, [selectedAffCode,isScoreValid, selectedPartyId, selectedStatus, selectedPassed, enteredAboNumber, startDate, startTime, statusDate, statusTime,errors.startTime,errors.startDate,errors.statusDate,errors.statusTime]);
  


  const getPartyIdFn = async (aboNumber,affiliateCode) => {  
    let endpoint = `/admin/api/v1/party-ids?aboNumber=${aboNumber}&salesPlanAff=${affiliateCode}`;
    const partyIdData = await makeRequest({
      method: 'GET',
      endpoint,
    });
    if (partyIdData != 'ERR_NETWORK' && partyIdData) {
      if(!partyIdData?.data?.length){
        return [];
      }
      const partyIdList = partyIdData?.data?.map(partyId =>{
        return {
          id: partyId.id,
          name: `${partyId.id} (${partyId.name})`,
        }
      });
      return partyIdList;
    }
    else if(partyIdData == 'ERR_NETWORK'){
      console.log('partyIdData: ', partyIdData);
      return [];
    }
  }

  const handleSaveChanges= async () => {

    const START_TIME_12_HOURS = dayjs(startTime, 'HH:mm').format('hh:mm A');
    const STATUS_TIME_12_HOURS = dayjs(statusTime, 'HH:mm').format('hh:mm A');

    const requestBody = {
      aboNumber : enteredAboNumber,
      partyId : selectedPartyId.id,
      salesPlanAff : selectedAffCode?.salesPlanAffCode,
      entryId: selectedCourse?.courseId,
      status: selectedStatus.id,
      passed: Boolean(selectedPassed.id),
      score: enteredScore,
      startDate: convertToUTC(startDate, START_TIME_12_HOURS, DATE_FORMAT),
      // startTime: startTime,
      statusDate: convertToUTC(statusDate, STATUS_TIME_12_HOURS, DATE_FORMAT), 
      // statusTime: statusTime,
      courseName: selectedCourse?.primaryTitle
    }

    let endpoint = `/admin/api/v1/learning-records`;
    const addLearningRecord = await makePostRequest_V2({
      method: 'POST',
      body: requestBody,
      endpoint,
    });

    console.log("addLearningRecord ", addLearningRecord);

    if (addLearningRecord?.success) {
      handleCloseAddRecordModal(true)
      dispatch(setNotificationData({
        show: true,
        type: 'SUCCESS',
        title: 'New Record Created',
        description: `A new learning record has been created within this course for ${selectedPartyId?.name}.`,
      }));
    }
    else {
      handleCloseAddRecordModal()
      dispatch(setNotificationData({
        show: true,
        type: 'WARNING',
        title: 'Failed to Add Learning Record',
        description: addLearningRecord?.message,
      }));
    }
  }


  const debouncedAboNumberSearch = useCallback(
    _debounce(async (event) => {
      const inputValue = event.target.value;

      try {
        const partyIdList = await getPartyIdFn(inputValue, selectedAffCode?.salesPlanAffCode);
        if (partyIdList.length > 0) {
          setPartyIdList(partyIdList);
          setSelectedPartyId(partyIdList[0]);
          setIsABOValid(true);
          setPartyIdDisabled(false);
        } else {
          setIsABOValid(false);
          setPartyIdDisabled(true);
        }
      } catch (error) {
        console.error("Error fetching party ID:", error);
        setIsABOValid(false);
        setPartyIdDisabled(true);
      } finally {
        setIsABOUserSearchLoading(false)
      }
    }, 800),[]);

    const handleAboNumberSearch = (event) => {
      setIsABOUserSearchLoading(true)
      debouncedAboNumberSearch(event)
    }

  /**
   * Date and Time Functions Starts Here
   */




  useEffect(() => {
    if (statusDate) {
      // Re-validate status date when start date changes
      const statusDateValidationResult = validateStatusDate(statusDate, startDate);
      if (statusDateValidationResult !== true) {
        setError('statusDate', { type: 'manual', message: statusDateValidationResult });
      } else {
        clearErrors('statusDate');
      }
    }
  
    if (statusTime) {
      // Re-validate status time when start date/time changes
      const statusTimeValidationResult = validateStatusTime(statusTime, statusDate, startTime, startDate);
      if (statusTimeValidationResult !== true) {
        setError('statusTime', { type: 'manual', message: statusTimeValidationResult });
      } else {
        clearErrors('statusTime');
      }
    }
  }, [startDate, startTime]);

  const padDateValue = (value) => value.padStart(2, '0');

  const getDateParts = (date) => {
    const dateParts = date.split('/').map(Number);

    switch(DATE_FORMAT) {
      case 'DD/MM/YYYY': {
        const [day, month, year] = dateParts;
        return { month, day, year }
      }
      
      case 'MM/DD/YYYY': {
        const [month, day, year] = dateParts;
        return { month, day, year }
      }
    }
  }

  const validateDate = (date,dateType='') => {
    // const DATE_SPLITTER = date.includes('-') ? '-' : '/';
    const DATE_SPLITTER = '/';
    // const dateParts = date.split(DATE_SPLITTER).map(Number);
    // // Check if we have enough parts for month, day, and year
    // if (dateParts.length !== 3) {
    //   return `Invalid ${dateType} Date`;
    // }

    const {month, day, year} = getDateParts(date);

    if (!month || !day || !year) {
      return `Invalid ${dateType} Date`;
    }
  
      // Validate date format
    if (isNaN(month) || isNaN(day) || isNaN(year) || year < 1000 || year > 9999 || month < 1 || month > 12 || day < 1 || day > 31) {
      return `Invalid ${dateType} Date`;
    }
  
    if (!dayjs(date, DATE_FORMAT, true).isValid()) {
      return `Invalid ${dateType} Date`;
    }

    if (dateType === "Start"  && dayjs(date, DATE_FORMAT).isAfter(dayjs(), 'day')) {
      return `Invalid ${dateType} Date`;
    }

    if (dateType === "Status"  && dayjs(date, DATE_FORMAT).isAfter(dayjs(), 'day')) {
      return `Invalid ${dateType} Date`;
    }

    return true; // Valid date
  };

  const validateTime = (time,statusType='') => {
    // Regex to check valid HH:MM format (24-hour) and ensure exactly 5 characters including the colon
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
  
    if (!timeRegex.test(time) || time.length !== 5) {
      return `Invalid ${statusType} Time`;
    }
  
    return true; // Valid time
  };



 

  const validateStatusTime = (statusTime, statusDate, startTime, startDate) => {
    if (!statusTime || !statusDate || !startTime || !startDate) return true; // Skip validation if fields are empty
  
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    if (!timeRegex.test(statusTime) || statusTime.length !== 5) {
      return 'Invalid Status Time';
    }
  
    const formattedStatusDate = dayjs(statusDate, DATE_FORMAT);
    const formattedStartDate = dayjs(startDate, DATE_FORMAT);
  
    if (formattedStatusDate.isSame(dayjs(), 'day')) {
      const currentTime = dayjs().format('HH:mm');
      if (statusTime > currentTime) {
        return 'Invalid Status Time';
      }
    }
  
    if (formattedStatusDate.isSame(formattedStartDate, 'day')) {
      if (statusTime < startTime) {
        return 'Invalid Status Time';
      }
    }

    if (formattedStatusDate.isBefore(formattedStartDate)) {
      return 'Invalid Status Time';
    }
  
    return true;
  };


  const validateDateWithCondition = (date, dateType, validateNext) => {
    const validationResult = validateDate(date, dateType);
    if (validationResult !== true) {
      return validationResult; // Return error message from validateDate
    }
    
    // Only proceed to the next validation if validateDate is successful
    if (validateNext) {
      return validateNext();
    }
  
    return true;
  };

  const validateTimeWithCondition = (time, validateNext) => {
    const validationResult = validateTime(time);
    if (validationResult !== true) {
      return validationResult; // Return error message from validateTime
    }
  
    // Only proceed to the next validation if validateTime is successful
    if (validateNext) {
      return validateNext();
    }
  
    return true;
  };

  const validateStartTime = (startTime, startDate) => {
    if (!startTime || !startDate) return true; // Skip validation if fields are empty
  
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    if (!timeRegex.test(startTime) || startTime.length !== 5) {
      return 'Invalid Start Time';
    }
  
    const formattedStartDate = dayjs(startDate, DATE_FORMAT);
    if (formattedStartDate.isSame(dayjs(), 'day')) {
      const currentTime = dayjs().format('HH:mm');
      if (startTime > currentTime) {
        return 'Invalid Start Time';
      }
    }
  
    return true;
  };

  const validateStatusDate = (statusDate, startDate) => {
    if (!statusDate || !startDate) return true; // Skip validation if fields are empty
  
    const formattedStatusDate = dayjs(statusDate, DATE_FORMAT);
    const formattedStartDate = dayjs(startDate, DATE_FORMAT);
    const today = dayjs();
  
    if (!formattedStatusDate.isValid()) {
      return 'Invalid Status Date';
    }
  
    if (formattedStatusDate.isAfter(today)) {
      return 'Invalid Status Date';
    }
  
    if (formattedStatusDate.isBefore(formattedStartDate)) {
      return 'Invalid Status Date';
    }
  
    return true;
  };

  /**
   * Date and Time Functions Ends Here
   */

  /**
   * Handle Add Record Submit button Logic
   */
  






  return (
    <div className={`${styles.modal_wrapper}`}>
      <div className={`${styles.custom_overlay} ${styles.tablet_open}`}>        
        <div className={styles.newRecordHeader}>
          <div className={styles.headerTitle}>Add New Learning Record </div>
          <div className={styles.cross_icon} onClick={()=> handleCloseAddRecordModal()}></div>
        </div>
        {/* Form Fields */}
        <div className={styles.form_main_wrapper}>
          {/* Affliate Code */}
          <div className={[styles.form_field].join(' ')}>
            <div className={styles.label_wrapper}>
              <div className={styles.label_text}>Affliate Code*</div>
            </div>
            <DropDown list={affCodeList} isRoaster={true} onChange={(value) => dispatch(setSelectedAffiliateCode(value))}></DropDown>
          </div>
          {/* ABO Number  */}
          <div className={[styles.form_field,isABOValid && !isABOUserSearchLoading ? styles.success_wrapper: enteredAboNumber.length && !isABOUserSearchLoading > 0 ? styles.error_wrapper:'' ].join(' ')}>
            <div className={styles.label_wrapper}>
              <div className={styles.label_text}>ABO Number*</div>
            </div>
            <div className={styles.input_wrapper}>
              <input
                type={'text'}
                placeholder={'Type full ABO Number here'}
                className={
                  styles.input
                  // ? styles.input
                  // : [styles.input, styles.disabled].join(' ')
                }
                maxLength={30}
                value={enteredAboNumber}
                onChange={(e) => {
                 setEnteredAboNumber(e.target.value);
                 handleAboNumberSearch(e)
                }}
              />
              { !isABOValid && !isABOUserSearchLoading && enteredAboNumber.length>0 ? <div className={styles.error_icon_red}></div> : null}
              { isABOValid && !isABOUserSearchLoading ? <div className={styles.success_green_tick}></div> : null}
            </div>
            { !isABOValid && !isABOUserSearchLoading && enteredAboNumber.length > 0 ? <div className={styles.error_message}> Registered ABO not found </div> :null }
            { isABOValid && !isABOUserSearchLoading ?<div className={styles.success_message}>Registered ABO found</div> : null}  
          </div>
          {/* Party ID  */}
          <div className={[styles.form_field].join(' ')}>
            <div className={styles.label_wrapper}>
              <div className={styles.label_text}>Party ID*</div>
            </div>
            <DropDown isDisabled={partyIdDisabled} list={partyIdDisabled ? [] : partyIdList} isRoaster={true} onChange={(value) => setSelectedPartyId(value)}></DropDown>
          </div>
          {/* Status and Passed  */}
          <div className={[styles.form_field, styles.form_field_row].join(' ')}>
            {/* Status  */}
            <div className={styles.left_field}>
              <div className={styles.label_wrapper}>
                <div className={styles.label_text}>Status*</div>
              </div>
              <DropDown defaultDisabledValue={{name:'Select a Completion Status'}} list={courseStatusDropdown} onChange={(value) => setSelectedStatus(value)} isRoaster={true}></DropDown>
            </div>
            <div className={styles.right_field}>
              <div className={styles.label_wrapper}>
                <div className={styles.label_text}>Passed</div>
              </div>
              <DropDown defaultDisabledValue={{name:'---'}} list={passedDropDown} onChange={(value) => setSelectedPassed(value)} isRoaster={true}></DropDown>
            </div>
          </div>
          {/* Score */}
          <div className={[styles.form_field,!isScoreValid ? styles.error_wrapper : ''].join(' ')}>
            <div className={styles.label_wrapper}>
              <div className={styles.label_text}>Score</div>
            </div>
            <div className={styles.input_wrapper}>
              <input
                type={'number'}
                placeholder={'000'}
                className={
                  styles.input
                }
                min={0}
                max={100}
                value={enteredScore}
                onChange={(e) => {
                  if(e.target.value>100 || e.target.value<0){
                    setIsScoreValid(false)
                  }else{
                    setIsScoreValid(true)
                    setEnteredScore(e.target.value)
                  }
                }}
              />
              {!isScoreValid ? <div className={styles.error_icon_red}></div> : null}
              
            </div>
            {!isScoreValid ? <div className={styles.error_message}>Score must be an integer number between 0 and 100
            </div> : null}
            
          </div>
          {/* Start Date and Start Time  */}
          <div className={[styles.form_field, styles.form_field_row].join(' ')}> 
            <div className={[styles.left_field,errors.startDate ? styles.error_wrapper : ''].join(' ')}>
              <div className={styles.label_wrapper}>
                <div className={styles.label_text}>Start Date*</div>
              </div>
              <div className={styles.input_wrapper}>
                <input
                  type={'text'}
                  placeholder={DATE_FORMAT}
                  {...register('startDate', { validate: (date) =>  validateDate(date, "Start")})}
                  className={
                    styles.input
                  }
                  maxLength={10}
                />
                 {errors.startDate ? <div className={styles.error_icon_red}></div> : null}
              </div>
              {errors.startDate && <div className={styles.error_message}>{errors.startDate.message}</div>}
            </div>
            <div className={[styles.right_field,errors.startTime ? styles.error_wrapper : ''].join(' ')}>
              <div className={styles.label_wrapper}>
                <div className={styles.label_text}>Start Time*</div>
              </div>
              <div className={styles.input_wrapper}>
                <input
                  type={'text'}
                  placeholder={'00:00'}
                  {...register('startTime', { 
                    validate: (time) => validateTimeWithCondition(time, () => validateStartTime(time, startDate))
                  })}       
                  className={
                    styles.input
                  }
                  maxLength={5}
                
                />
                {errors.startTime ? <div className={styles.error_icon_red}></div> : null}
              </div>
              {errors.startTime && <div className={styles.error_message}>{errors.startTime.message}</div>}
            </div>
          </div>
          {/* Status Date and Status Time  */}
          <div className={[styles.form_field, styles.form_field_row].join(' ')}>
            <div className={[styles.left_field,errors.statusDate ? styles.error_wrapper : ''].join(' ')}>
              <div className={styles.label_wrapper}>
                <div className={styles.label_text}>Status Date*</div>
              </div>
              <div className={styles.input_wrapper}>
                <input
                  type={'text'}
                  placeholder={DATE_FORMAT}
                  {...register('statusDate', { 
                    validate: (date) => validateDateWithCondition(date, "Status", () => validateStatusDate(date, startDate))
                  })}
                  className={
                    styles.input
                  }
                  maxLength={10}
                />
                 {errors.statusDate ? <div className={styles.error_icon_red}></div> : null}
              </div>
              {errors.statusDate && <div className={styles.error_message}>{errors.statusDate.message}</div>}
            </div>
            <div className={[styles.right_field,errors.statusTime ? styles.error_wrapper : ''].join(' ')}>
              <div className={styles.label_wrapper}>
                <div className={styles.label_text}>Status Time*</div>
              </div>
              <div className={styles.input_wrapper}>
                <input
                  type={'text'}
                  placeholder={'00:00'}
                  {...register('statusTime', { 
                    validate: (time) => validateTimeWithCondition(time, () => validateStatusTime(time, statusDate, startTime, startDate))
                  })}
                  className={
                    styles.input
                  }
                  maxLength={5}
                />
                 {errors.statusTime  ? <div className={styles.error_icon_red}></div> : null}
              </div>
              {errors.statusTime && <div className={styles.error_message}>{errors.statusTime.message}</div>}
            </div>
          </div>
        </div>
        <div className={styles.btn_wrapper}>
          <button className={styles.btn} onClick={() => handleCloseAddRecordModal()}>Cancel</button>
          <button className={[styles.btn,isAddRecordEnabled ? styles.active : styles.disabled].join(' ')} onClick={() => handleSaveChanges()}>Add Record</button>
        </div>
      </div>
    </div>
  );
};

export default RoasterNewComponent;