import React, {useState, useEffect} from 'react';
import styles from './LearningPath.module.scss';
import Header from '../shared/header';
import {connect} from 'react-redux';
import FilterComponent from '../shared/filter';
import TopBarComponenent from '../audienceManagement/TopBar';
import EmptyScreenComponent from '../shared/emptyScreen';
import {makeRequest} from '../../components/utils/APIsUtils/httpsUtils';
import findIndex from 'lodash/findIndex';
import {
  setSelectedNavigationOption,
  setIsUserLoggedIn,
  setDashboardNotificationCountApi,
  setNotificationListApi,
  setNewTranslationList,
} from '../../redux/actions/uiActions';
import {
  setErrorStateTemplate,
  setNotificationData,
  setCountryList,
} from '../../redux/actions/uiActions';
import DeleteModalComponent from '../audienceManagement/DeleteModal';
import Notification from '../shared/notification';
import {
  fetchLearningPathList,
  closeDrawerModal,
  getCourseButtonStatus,
  OnClickOfArchiveLP,
  changeCourseStatus,
  fetchCoursesListWithParams,
  fetchLearningPathDetails,
  handleSearchInput,
  fetchCategories,
  selectedAudiGrp,
  handleDeleteModal,
  handleClickOutside,
  deleteLearningPath,
  handleFilterApplyNew,
  //_initialFilterData,
  DrawerLP,
  CardViewLP,
  ListViewLP,
} from '../utils/LearningPAthUtils/LandingPageUtils';
import {dateIsoToDate, dateIsoToTime} from '../utils/commonUtil';
import DrawerComponent from '../audienceManagement/Drawer';
import {Hint} from 'react-autocomplete-hint';
import {useHistory} from 'react-router-dom';
import DrawerFailScreen from '../shared/drawerFailScreen';
import {setNewLPData} from '../../redux/actions/learningPathActions';
import {setNewThumbnailId} from '../../redux/actions/newCourseActions';
import {setMainImageBackup} from '../../redux/actions/imageCrop/imageCropAction';
import { setAllLearningPathList } from '../../redux/actions/learningPathManagementActions';

const LearningPathComponent = (props) => {
  const {
    setSelectedNavigationOption,
    setIsUserLoggedIn,
    setNotificationData,
    notificationData,
    setErrorStateTemplate,
    setNotificationListApi,
    setDashboardNotificationCountApi,
    currentUserId,
    setNewLPData,
    setNewTranslationList,
    addedTranslationListState,
    countryList,
    setCountryList,
    remoteConfig,
    newThumbnailId,
    newThumbnailImage,
    newImageData,
    thumbnailImage169,
    setNewThumbnailId,
    setMainImageBackup,
    newLPData,
    setAllLearningPathList = () => {}
  } = props;

  const [isCardView, setIsCardView] = useState(true);
  const [isListView, setIsListView] = useState(false);
  const [search, setSearch] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [isDrawer, setDrawer] = useState(false);
  const [isCoursesDrawerList, setCoursesDrawerList] = useState(true);
  const [isOnline, setOnline] = useState(true);
  const [isDelete, setDelete] = useState(false);
  const [selectedLearningPath, setSelectedLearningPath] = useState('');
  const [isDrawerAPIFailed, setDrawerAPIFailed] = useState(false);
  const router = useHistory();
  const {query = {}} = router.location;
  const [drawerIdForBrokenLink, setDrawerIdForBrokenLink] = useState('');
  const [drawerHeight, setDrawerHeight] = useState('');

  const _initialFilterData = {
    tiles: [
      {
        index: 0,
        id: 'DRAFT',
        title: 'Draft',
        className: 'draft',
        filterParameter: 'course_state',
        typeOfFilter: 'tiles',
        checked: false,
      },
      {
        index: 1,
        id: 'LIVE',
        title: 'Live',
        className: 'goLive',
        filterParameter: 'course_state',
        typeOfFilter: 'tiles',
        checked: false,
      },
      {
        index: 2,
        id: 'OFFLINE',
        title: 'Offline',
        className: 'goOffline',
        filterParameter: 'course_state',
        typeOfFilter: 'tiles',
        checked: false,
      },
      {
        index: 3,
        id: 'ARCHIVE',
        title: 'Archive',
        className: 'archive',
        filterParameter: 'course_state',
        typeOfFilter: 'tiles',
        checked: false,
      },
    ],
    filtersList: [
      {
        filterType: 'RADIO',
        filterTypeData: {
          sectionTitle: 'Sort By',
          sectionData: [
            {
              index: 0,
              name: 'Recent Activity',
              id: 1,
              checked: true,
              filterParameter: 'name',
              filterParameterType: 'default',
              typeOfFilter: 'RADIO',
            },
            {
              index: 1,
              name: 'A - Z',
              id: 2,
              checked: false,
              filterParameter: 'title',
              filterParameterType: 'ASC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 2,
              name: 'Z - A',
              id: 3,
              checked: false,
              filterParameter: 'title',
              filterParameterType: 'DESC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 3,
              name: 'Newest To Oldest',
              id: 4,
              checked: false,
              filterParameter: 'launch_date',
              filterParameterType: 'TIME-ASC',
              typeOfFilter: 'RADIO',
            },
            {
              index: 4,
              name: 'Oldest To Newest',
              id: 5,
              checked: false,
              filterParameter: 'launch_date',
              filterParameterType: 'TIME-DESC',
              typeOfFilter: 'RADIO',
            },
          ],
        },
      },
      {
        filterType: 'CHECKBOX',
        filterTypeData: {
          sectionTitle: 'Categories',
          sectionData: [],
        },
      },
    ],
  };
  window.addEventListener('online', () => {
    setOnline(true);
    setErrorStateTemplate({
      status: false,
      variant: 'serverError',
    });
    window.location.reload();
  });
  window.addEventListener('offline', () => {
    setOnline(false);
    setErrorStateTemplate({
      status: true,
      variant: 'serverError',
    });
  });
  const [predictedSearch, setPredictedSearch] = useState([]);
  const [isSearchFilterEmptyState, setSearchFilterEmptyState] = useState(false);
  const [filterDataTemp, setFilterDataTemp] = useState(new Object(_initialFilterData));
  const [learningPathAPIResponse, setLearningPathAPIResponse] = useState([]);
  const [learningPathAPIResponseCopy, setLearningPathAPIResponseCopy] = useState([]);
  const [learningPathDetailsDrawerResponse, setLearningPathDetailsDrawerResponse] = useState({});
  const [filterData, setFilterData] = useState(_initialFilterData);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(async () => {
    setIsLoading(true);
    setOnline(navigator.onLine);
    setIsUserLoggedIn(true);
    setSelectedNavigationOption('LEARNING-PATH');
    const initialize = async () => {
      if (remoteConfig?.user_group_country_targeting) await fetchCountriesList();
    };
    initialize();

    await fetchLearningPathList({
      makeRequest,
      setLearningPathAPIResponse,
      setLearningPathAPIResponseCopy,
      setSearchFilterEmptyState,
      isOnline,
      setErrorStateTemplate,
      setAllLearningPathList
    });
    if (query?.learningPathId?.length > 0) {
      await fetchLearningPathDetails({
        item: query?.learningPathId,
        makeRequest,
        setLearningPathDetailsDrawerResponse,
        setDrawerIdForBrokenLink,
        drawerIdForBrokenLink,
        setDrawerAPIFailed,
      });

      setTimeout(() => {
        setDrawer(true);
        setSelectedLearningPath(query?.learningPathId);
        var element = document.getElementById(query?.learningPathId);
        element?.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'nearest'});
      }, 2000);
    }
    if (router.location.search) {
      let stringId = router.location.search;
      const stringArray = stringId.split('=');
      await fetchLearningPathDetails({
        item: stringArray[1],
        makeRequest,
        setLearningPathDetailsDrawerResponse,
        setDrawerIdForBrokenLink,
        drawerIdForBrokenLink,
        setDrawerAPIFailed,
      });

      setTimeout(() => {
        setDrawer(true);
        setSelectedLearningPath(stringArray[1]);
        var element1 = document.getElementById(stringArray[1]);
        element1?.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'nearest'});
      }, 2000);
    }
    await fetchCategories({
      makeRequest,
      filterData,
      findIndex,
      setFilterData,
    });
    setTimeout(() => {
      setIsLoading(false);
    }, 350);
  }, []);

  useEffect(() => {
    if (search && search.length && search.length >= 1) {
      let result;
      result = learningPathAPIResponseCopy.filter((user) =>
        user.title.toLowerCase().includes(search.toLowerCase()),
      );
      //for checking length of LP group name
      result?.sort(function (one, other) {
        return one?.title?.length - other?.title?.length;
      });
      //for checking the serach char index in LP group name
      result?.sort(function (one, other) {
        return (
          one.title?.toLowerCase().indexOf(search?.toLowerCase()) -
          other.title?.toLowerCase().indexOf(search?.toLowerCase())
        );
      });
      setLearningPathAPIResponse(result);
    } else {
      setLearningPathAPIResponse(learningPathAPIResponseCopy);
    }
  }, [search, learningPathAPIResponseCopy]);

  useEffect(() => {
    document.addEventListener('mousedown', (e) => {
      handleClickOutside({e, setDrawer, setSelectedLearningPath, isDrawer});
    });
    setDrawerHeight(140);
  }, [isDrawer]);

  const fetchCountriesList = async () => {
    const fetchCountry = await makeRequest({
      method: 'GET',
      endpoint: `/api/v1/market/countries`,
    });
    if (fetchCountry?.data) {
      let country = Object.entries(fetchCountry?.data)
        .map(([countryName, countryCode]) => ({
          countryName,
          countryCode,
          checked: true,
        }))
        .sort((a, b) => (a.countryName > b.countryName ? 1 : -1));
      setCountryList(country);
    }
    return true;
  };

  const clearGlobalTranslation = () => {
    setNewTranslationList([]);
  };

  const newButtonHandle = () => {
    clearGlobalTranslation();
    setFilterData(filterDataTemp);
    setNewLPData(null);
    setNewThumbnailId(null);
    setMainImageBackup(null);
    router.push({
      pathname: '/newLearningPath',
      query: {id: null},
      search: `?id=${null}&sub_header=learning_path_description`,
    });
  };

  return (
    <div className={[styles.learning_path_mgt_wrapper].join(' ')}>
      {/* Header Component */}
      <Header
        headerName="Learning Path Management"
        headerXSName=""
        btnName1="Cancel"
        btnName2="Save changes"
        isHeaderXS={false}
        isBtn1={false}
        isBtn2={false}
      />
      {/* Header Component */}
      <div className={[styles.audience_wrapper, isDrawer ? styles.is_drawer_open : ''].join(' ')}>
        {/* Topbar Starts */}
        <TopBarComponenent
          setIsCardView={(val) => {
            setIsLoading(true);
            setIsCardView(val);
            setTimeout(() => {
              val && learningPathAPIResponse && setIsLoading(false);
            }, 800);
          }}
          setIsListView={(val) => {
            setIsLoading(true);
            setIsListView(val);
            setTimeout(() => {
              val && learningPathAPIResponse && setIsLoading((prev) => false);
            }, 800);
          }}
          isCardView={isCardView}
          isListView={isListView}
          showGridListSwitch={true}
          isLearningPath={true}
          isDrawerOpen={false}
          isToggleSwitch={false}
          placeholderName={'Search Learning Path'}
          setPredictedSearch={setPredictedSearch}
          predictedSearch={predictedSearch}
          searchListFunction={(e) => {
            if (e != '') {
              setSearch(e?.target?.value?.trim());
            } else if (e == '') {
              setSearch('');
            }
          }}
          handleSearchInput={(event) => {
            handleSearchInput({
              event,
              setSearch,
              setPredictedSearch,
              learningPathAPIResponse,
            });
          }}
          filterData={filterData}
          //col6={true}
          handleFilterApply={(params) => {
            handleFilterApplyNew({
              params,
              filterData,
              makeRequest,
              setFilterData,
              setFilterDataTemp,
              setLearningPathAPIResponse,
              setLearningPathAPIResponseCopy,
              setSearchFilterEmptyState,
              setErrorStateTemplate,
              setIsLoading,
            });
          }}
          handleFilterCancle={() => setShowFilters(false)}
          isOpenSubCategories={false}
          addNewButton={true}
          addNewButtonText={'New Learning Path'}
          isDrawer={isDrawer}
          setDrawer={setDrawer}
          searchBarFrom={'LEARNING-PATH'}
          newButtonHandle={newButtonHandle}
        />
        {/* Topbar Ends */}

        <div className={styles.inner_wrapper}>
          {/* Card View Starts */}

          <CardViewLP
            React={React}
            isLoading={isLoading}
            isCardView={isCardView}
            learningPathAPIResponse={learningPathAPIResponse}
            styles={styles}
            isDrawer={isDrawer}
            learningPathDetailsDrawerResponse={learningPathDetailsDrawerResponse}
            setDrawer={setDrawer}
            setSelectedLearningPath={setSelectedLearningPath}
            makeRequest={makeRequest}
            setLearningPathDetailsDrawerResponse={setLearningPathDetailsDrawerResponse}
            setCoursesDrawerList={setCoursesDrawerList}
            selectedLearningPath={selectedLearningPath}
            dateIsoToDate={dateIsoToDate}
            isListView={isListView}
            EmptyScreenComponent={EmptyScreenComponent}
            isSearchFilterEmptyState={isSearchFilterEmptyState}
            setDrawerAPIFailed={setDrawerAPIFailed}
            setDrawerIdForBrokenLink={setDrawerIdForBrokenLink}
            drawerIdForBrokenLink={drawerIdForBrokenLink}
          />
          {/* Card View Ends */}

          {/* List View of Starts */}
          <ListViewLP
            React={React}
            isLoading={isLoading}
            isCardView={isCardView}
            learningPathAPIResponse={learningPathAPIResponse}
            styles={styles}
            isDrawer={isDrawer}
            learningPathDetailsDrawerResponse={learningPathDetailsDrawerResponse}
            setDrawer={setDrawer}
            setSelectedLearningPath={setSelectedLearningPath}
            makeRequest={makeRequest}
            setLearningPathDetailsDrawerResponse={setLearningPathDetailsDrawerResponse}
            setCoursesDrawerList={setCoursesDrawerList}
            selectedLearningPath={selectedLearningPath}
            dateIsoToDate={dateIsoToDate}
            isListView={isListView}
            EmptyScreenComponent={EmptyScreenComponent}
            isSearchFilterEmptyState={isSearchFilterEmptyState}
            dateIsoToTime={dateIsoToTime}
            setDrawerAPIFailed={setDrawerAPIFailed}
            setDrawerIdForBrokenLink={setDrawerIdForBrokenLink}
            drawerIdForBrokenLink={drawerIdForBrokenLink}
            fetchLearningPathDetails={fetchLearningPathDetails}
          />
          {/* List View of Ends */}

          {/* Drawer Starts */}
          <DrawerLP
            isDrawer={isDrawer}
            closeDrawerModal={closeDrawerModal}
            setDrawer={setDrawer}
            setSelectedLearningPath={setSelectedLearningPath}
            learningPathDetailsDrawerResponse={learningPathDetailsDrawerResponse}
            setNotificationData={setNotificationData}
            OnClickOfArchiveLP={OnClickOfArchiveLP}
            dateIsoToTime={dateIsoToTime}
            dateIsoToDate={dateIsoToDate}
            makeRequest={makeRequest}
            setLearningPathAPIResponse={setLearningPathAPIResponse}
            setLearningPathAPIResponseCopy={setLearningPathAPIResponseCopy}
            setSearchFilterEmptyState={setSearchFilterEmptyState}
            isOnline={isOnline}
            setErrorStateTemplate={setErrorStateTemplate}
            fetchLearningPathDetails={fetchLearningPathDetails}
            setLearningPathDetailsDrawerResponse={setLearningPathDetailsDrawerResponse}
            handleDeleteModal={handleDeleteModal}
            setDelete={setDelete}
            isDelete={isDelete}
            changeCourseStatus={changeCourseStatus}
            setCoursesDrawerList={setCoursesDrawerList}
            isCoursesDrawerList={isCoursesDrawerList}
            getCourseButtonStatus={getCourseButtonStatus}
            styles={styles}
            React={React}
            DrawerFailScreen={DrawerFailScreen}
            isDrawerAPIFailed={isDrawerAPIFailed}
            setDrawerAPIFailed={setDrawerAPIFailed}
            setDrawerIdForBrokenLink={setDrawerIdForBrokenLink}
            drawerIdForBrokenLink={drawerIdForBrokenLink}
            setDashboardNotificationCountApi={setDashboardNotificationCountApi}
            setNotificationListApi={setNotificationListApi}
            currentUserId={currentUserId}
            setDrawerHeight={setDrawerHeight}
            drawerHeight={drawerHeight}
            clearGlobalTranslation={clearGlobalTranslation}
            setNewThumbnailId={setNewThumbnailId}
            setMainImageBackup={setMainImageBackup}
            setAllLearningPathList={setAllLearningPathList}
          />
          {/* Drawer Ends */}

          {/* Delete Component Starts */}
          {isDelete && (
            <DeleteModalComponent
              closeDeleteModal={() => {
                handleDeleteModal({setDelete, isDelete});
              }}
              saveDeleteChanges={() => {
                handleDeleteModal({setDelete, isDelete});
              }}
              deleteCall={() =>
                deleteLearningPath({
                  learningPathDetailsDrawerResponse,
                  makeRequest,
                  setNotificationData,
                  setLearningPathAPIResponse,
                  setLearningPathAPIResponseCopy,
                  setSearchFilterEmptyState,
                  isOnline,
                  setErrorStateTemplate,
                  setDrawer,
                  filterData,
                })
              }
              name={learningPathDetailsDrawerResponse?.primaryTitle}
              type="Learning Path"
            ></DeleteModalComponent>
          )}
          {/* Delete Component ends */}

          {notificationData?.show && <Notification />}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  notificationData: state && state.uiState && state.uiState.notificationData,
  currentUserId: state && state.uiState && state.uiState.currentUserId,
  addedTranslationListState: state && state.uiState && state.uiState.addedTranslationListState,
  countryList: state && state.uiState && state.uiState.countryList,
  remoteConfig: state && state.uiState && state.uiState.remoteConfig,
  thumbnailImage169: state && state.croppedImage && state.croppedImage.thumbnailImage169,
  newImageData: state && state.courseState && state.courseState.newImageData,
  newThumbnailImage: state && state.courseState && state.courseState.newThumbnailImage,
  newThumbnailId: state && state.courseState && state.courseState.newThumbnailId,
  newLPData: state && state.LPState && state.LPState.newLPData,
});

const mapDispatchToProps = (dispatch) => ({
  setIsUserLoggedIn: (options) => dispatch(setIsUserLoggedIn(options)),
  setSelectedNavigationOption: (options) => dispatch(setSelectedNavigationOption(options)),
  setNotificationData: (options) => dispatch(setNotificationData(options)),
  setErrorStateTemplate: (options) => dispatch(setErrorStateTemplate(options)),
  setDashboardNotificationCountApi: (options) =>
    dispatch(setDashboardNotificationCountApi(options)),
  setNotificationListApi: (options) => dispatch(setNotificationListApi(options)),
  setNewLPData: (options) => dispatch(setNewLPData(options)),
  setNewTranslationList: (options) => dispatch(setNewTranslationList(options)),
  setCountryList: (options) => dispatch(setCountryList(options)),
  setNewThumbnailId: (options) => dispatch(setNewThumbnailId(options)),
  setMainImageBackup: (options) => dispatch(setMainImageBackup(options)),
  setAllLearningPathList: (options) => dispatch(setAllLearningPathList(options))
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(LearningPathComponent));
