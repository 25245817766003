import RoasterService from "../../../service/RoasterService";
import { setNotificationData } from "../uiActions";

export const SET_ABO_SEARCH_TERM = 'SET_ABO_SEARCH_TERM';
export const SET_COURSE_STATUS_DROPDOWN = 'SET_COURSE_STATUS_DROPDOWN';
export const SET_AFFILIATE_CODE = 'SET_AFFILIATE_CODE';
export const SET_ABO_LIST_LOADER = 'SET_ABO_LIST_LOADER';
export const SET_ABO_LIST = 'SET_ABO_LIST';
export const SET_SELECTED_ABO_LIST = 'SET_SELECTED_ABO_LIST';
export const SET_ABO_LIST_SORT_PROPS = 'SET_ABO_LIST_SORT_PROPS';
export const SET_SELECTED_AFF_CODE = 'SET_SELECTED_AFF_CODE';
export const SET_ABO_LIST_PAGINATION_PROPS = 'SET_ABO_LIST_PAGINATION_PROPS';
export const SET_EDIT_FORM_COURSE_START_DATE = 'SET_EDIT_FORM_COURSE_START_DATE';
export const SET_EDIT_FORM_COURSE_STATUS_DATE = 'SET_EDIT_FORM_COURSE_STATUS_DATE';
export const SET_EDIT_FORM_COURSE_PASSED_STATUS = 'SET_EDIT_FORM_COURSE_PASSED_STATUS';
export const SET_EDIT_FORM_COURSE_SCORE = 'SET_EDIT_FORM_COURSE_SCORE';
export const SET_ABO_LIST_RESET_PAGINATION_PROPS = 'SET_ABO_LIST_RESET_PAGINATION_PROPS';

export const setEditFormCourseScore = (options) => {
  return {
    type: SET_EDIT_FORM_COURSE_SCORE,
    payload: options,
  };
};

export const setAboSearchTerm = (options) => {
  return {
    type: SET_ABO_SEARCH_TERM,
    payload: options,
  };
};

export const setCourseStatusDropdown = (options) => {
  return {
    type: SET_COURSE_STATUS_DROPDOWN,
    payload: options,
  };
};

export const setAffiliateCode = (options) => {
  return {
    type: SET_AFFILIATE_CODE,
    payload: options,
  };
};

export const setSelectedAffiliateCode = (options) => {
  return {
    type: SET_SELECTED_AFF_CODE,
    payload: options,
  };
};

export const setAboListLoader = (options) => {
  return {
    type: SET_ABO_LIST_LOADER,
    payload: options,
  };
};

export const setAboList = (options) => {
  return {
    type: SET_ABO_LIST,
    payload: options,
  };
};

export const setSelectedAboList = (options) => {
  return {
    type: SET_SELECTED_ABO_LIST,
    payload: options,
  };
};

export const setAboListSortProps = (options) => {
  return {
    type: SET_ABO_LIST_SORT_PROPS,
    payload: options,
  };
};

export const setAboListPaginationProps = (options) => {
  return {
    type: SET_ABO_LIST_PAGINATION_PROPS,
    payload: options,
  };
};

export const setAboListResetPaginationProps = (options) => {
  return {
    type: SET_ABO_LIST_RESET_PAGINATION_PROPS,
    payload: options,
  };
};

export const setEditFormStartDate = (options) => {
  return {
    type: SET_EDIT_FORM_COURSE_START_DATE,
    payload: options,
  };
};

export const setEditFormStatusDate = (options) => {
  return {
    type: SET_EDIT_FORM_COURSE_STATUS_DATE,
    payload: options,
  };
};

export const setCoursePassedDropdown = (options) => {
  return {
    type: SET_EDIT_FORM_COURSE_PASSED_STATUS,
    payload: options,
  };
};

export const fetchAboList = ({ payload, paginationKeys = []}) => {
  return async (dispatch) => {
    try {
      dispatch(setAboListLoader(true));

      const response = await RoasterService.getAboList(payload);

      const { learningRecords = [], lastEvaluatedKey, createdAt } = response?.data;

      dispatch(setAboList(learningRecords));

      if(learningRecords?.length > 0 && lastEvaluatedKey) {
        const exclusiveStartKeyIndex = paginationKeys.findIndex(p => p?.exclusiveStartKey === lastEvaluatedKey);

        if(exclusiveStartKeyIndex === -1) {
          const newKeys = [...paginationKeys, { exclusiveStartKey: lastEvaluatedKey, createdAt }];
          dispatch(setAboListPaginationProps({ keys: newKeys }));
        }
      }
      
      dispatch(setAboListLoader(false));
    } catch (error) {
      console.log('error', error);
      dispatch(setAboList([]));
    } finally {
      dispatch(setAboListLoader(false));
    }
  };
};

export const deleteLearningRecords = (
  {selectedAboList, selectedCourse, selectedAffCode},
  fetchAboListPayload,
) => {
  return async (dispatch) => {
    try {
      dispatch(setAboListLoader(true));

      const response = await RoasterService.deleteLearningRecords({
        selectedAboList,
        selectedCourse,
        selectedAffCode,
      });

      if(response?.status === 'Success'){
        dispatch(setNotificationData({
          show: true,
          type: 'SUCCESS',
          title: 'Record Deletion Completed',
          description: `Deletion of the selected course record(s) saved and will be reflected within the system.`,
        }));
      } else if(response?.status === 'Fail'){
        dispatch(setNotificationData({
          show: true,
          type: 'WARNING',
          title: `Failed To Delete`,
          description: `Unable to delete selected course record(s)`,
        }));
      }

      dispatch(setAboListResetPaginationProps());
      dispatch(fetchAboList(fetchAboListPayload));
      console.log('response-->', response);
    } catch (error) {
      console.log('error', error);
      dispatch(setNotificationData({
        show: true,
        type: 'WARNING',
        title: `Failed To Delete`,
        description: `Unable to delete selected course record(s)`,
      }));
    } finally {
      dispatch(setAboListLoader(false));
    }
  };
};
