import { SET_ALL_COURSES_LIST, SET_SELECTED_COURSE } from "../actions/courseManagementActions";

const initialState = {
    allCoursesList: [],
    selectedCourse: null,
};

export const courseManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_COURSES_LIST: {
            return { ...state, allCoursesList: action.payload };
        }
        case SET_SELECTED_COURSE: {
            return { ...state, selectedCourse: action.payload };
        }
        default: {
            return state;
        }
    }
};
