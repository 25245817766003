export const SET_ALL_COURSES_LIST = 'SET_ALL_COURSES_LIST';
export const SET_SELECTED_COURSE = 'SET_SELECTED_COURSE';

export const setAllCoursesList = (options) => {
    return {
        type: SET_ALL_COURSES_LIST,
        payload: options,
    };
};

export const setSelectedCourse = (options) => {
    return {
        type: SET_SELECTED_COURSE,
        payload: options,
    };
};
